import React, {useState, useEffect, useRef} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import "../style.css";
import api from "../../../lib/API";
import localStorageService from "../../../lib/LocalStorageService";

export default function ChangePasswordDialog(props){
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const confirmRef = useRef(null);
    const oldPasswordRef = useRef(null);
    const newPasswordRef = useRef(null);
    const confirmPasswordRef = useRef(null);

    const footerTemplate = () => {
        return (
        <div className="row">
            <div className="col-7">
                {errorMessage && <p style={{textAlign:"left !important"}} className="float-left mt-2 text-left text-danger m-0">{errorMessage}</p>}
            </div>
            <div className="col-5">
                <button className="btn cancel mr-2 mb-2 pl-4 pr-4" onClick={handleExit}>Cancel</button>
                <button
                ref={confirmRef}
                className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                disabled={!(currentPassword && newPassword && confirmPassword && (newPassword === confirmPassword))}
                onClick={()=>changePassword()}>Confirm</button>
            </div>
        </div>)
    }

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(!isConfirmed){
                confirmRef.current.removeAttribute("disabled");
                confirmRef.current.innerHTML = `<span>Confirm</span>`;
            }
            else{
                confirmRef.current.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-2">Confirm</span>`;
                confirmRef.current.setAttribute("disabled","disabled");
            }
        }
        return () => mounted = false;
    },[isConfirmed])

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(newPassword || confirmPassword){
                if(newPassword!==confirmPassword){
                    newPasswordRef.current.cssClass="e-error";
                    confirmPasswordRef.current.cssClass="e-error";
                    setErrorMessage("Password does not match");
                }
                else{
                    newPasswordRef.current.cssClass="";
                    confirmPasswordRef.current.cssClass="";
                    setErrorMessage("");
                }
            }
            else{
                newPasswordRef.current.cssClass="";
                confirmPasswordRef.current.cssClass="";
                setErrorMessage("");
            }

        }
        return () => mounted = false;
    },[newPassword,confirmPassword])

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(oldPasswordRef.current.cssClass === "e-error"){
                oldPasswordRef.current.cssClass = '';
            }
        }
        return () => mounted = false;
    },[currentPassword])

    const changePassword = () => {
        setIsConfirmed(true);
        api.post('/profile/password',{newPassword:confirmPassword, currentPassword:currentPassword}).then(res=>{
            console.log(res.data);
            localStorageService.setPwdExpiryReminder("false");
            handleExit();
        }, err => {
            console.log(err);
            setIsConfirmed(false);
            setErrorMessage(err.response.data.message);
            if(err.response.data.errorFieldList.length !== 0)
                err.response.data.errorFieldList.forEach(element => {
                    switch (element) {
                        case "old_password":
                            oldPasswordRef.current.cssClass = "e-error";
                            break;
                        case "password":
                            newPasswordRef.current.cssClass = "e-error";
                            break;
                        default:
                            break;
                    }
                });
        })

    }


     // function to handle the close on overlay
    const handleExit = () => {
        newPasswordRef.current.cssClass="";
        confirmPasswordRef.current.cssClass="";
        oldPasswordRef.current.cssClass = '';
        setIsConfirmed(false);
        props.setVisibility(false);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setErrorMessage('');
    }
    return (
        <div>
            <div className="App" id='dialog-target'>
                <DialogComponent 
                width="40%"
                target='#dialog-target' 
                close={handleExit} 
                header='Change Password' 
                visible={props.visibility} 
                showCloseIcon={false} 
                footerTemplate={footerTemplate}
                isModal={true}
                // ref={dialog => dialogInstance = dialog} 
                overlayClick = {handleExit}>
                
                    <div className="textboxes">
                        <div className="row">
                            <div className="col">
                                <h6>Current Password</h6>
                                <TextBoxComponent ref={oldPasswordRef} type="password" value={currentPassword} input={e=>setCurrentPassword(e.value)} placeholder="Current Password" floatLabelType="Never"/>
                            </div>
                            <div className="col"></div>
                        </div>
                    </div>
                    <div className="textboxes">
                        <div className="row ">
                            <div className="col ">
                                <h6>New Password</h6>
                                <TextBoxComponent ref={newPasswordRef} type="password" value={newPassword} input={e=>setNewPassword(e.value)} placeholder="New Password" floatLabelType="Never"/>
                            </div>
                            <div className="col">
                                <h6>Confirm New Password</h6>
                                <TextBoxComponent ref={confirmPasswordRef} type="password" value={confirmPassword} input={e=>setConfirmPassword(e.value)} placeholder="Confirm New Password" floatLabelType="Never"/>
                            </div>
                        </div>
                    </div>
                    <div className="constraint text-secondary m-2">
                        <p className="content-text-inactive" style={{color:"#6F7388"}}>• Password length no shorter than 10 characters.</p>
                        <p className="content-text-inactive" style={{color:"#6F7388"}}>• Password must be set with a combination from 3 of the following:</p>
                        <p className="content-text-inactive" style={{color:"#6F7388"}}>&emsp;{`- Uppercase letters (A-Z)`}</p>
                        <p className="content-text-inactive" style={{color:"#6F7388"}}>&emsp;{`- Lowercase letters (a-z)`}</p>
                        <p className="content-text-inactive" style={{color:"#6F7388"}}>&emsp;{`- Numbers (0-9)`}</p>
                        <p className="content-text-inactive" style={{color:"#6F7388"}}>&emsp;{`- Non-alphanumeric characters (e.g., !@#$%)`}</p>
                        
                        
                    </div>
                </DialogComponent>
            </div>
        
        
        </div>
    );

};