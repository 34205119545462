import React,{useState, useRef, useEffect} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';

import { useHistory } from "react-router-dom";
import localStorageService from "../../../lib/LocalStorageService";
import api from '../../../lib/API';

export default function TermsAndConditions(props){
    const [isAccepted, setIsAccepted] = useState(false);
    const acceptRef = useRef(null);

    const history = useHistory();
    const login = () => {
        let path = "/";
        history.push(path);
    };

    useEffect(()=>{
        let mounted = true;

        if(mounted && props.visibility){
            if(!isAccepted){
                acceptRef.current.removeAttribute("disabled");
                acceptRef.current.innerHTML = `<span>Accept</span>`;
            }
            else{
                acceptRef.current.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-2">Accept</span>`;
                acceptRef.current.setAttribute("disabled","disabled");
            }
        }

        return () => mounted = false;
    },[isAccepted])

    const footerTemplate = () => {
        return(
            <div className="mb-3 mr-2">
                <button className="mr-2 btn cancel pl-4 pr-4"
                onClick={()=>{
                    localStorageService.clearToken();
                    props.setVisibility(false);
                }}>Decline</button>
                <button ref={acceptRef}
                className="btn btn-dark pl-4 pr-4"
                onClick={()=>{
                    setIsAccepted(true);
                    props.setVisibility(false);
                    onAccept();
                }}>Accept</button>
            </div>
        )
    }

    const onAccept = () => {
        localStorageService.getAccessToken();

        api.post('/profile/acceptTerms').then(async (res) => {
            const {location, history} = props;
            const selected = parseInt(new URLSearchParams(location.search).get('type') || 1);
            const {from} = location.state || {from: {pathname: "/", search: ""}};
            setTimeout(() => history.replace(from), 100)
            setIsAccepted(false);
            console.log(res.data);
        }, err => {
            //error handle
            console.log(err.response.data.message);
            
            props.setErrorMessage(err.response.data.message);
            props.setIsLoading(false);
            setIsAccepted(false);
        })
    };


    return (
        <div>
            <div className="App" id='dialog-target'>
                <DialogComponent 
                width="45%"
                target='#dialog-target' 
                close={()=>props.setVisibility(false)} 
                header='Copyright (Terms and Conditions of Use)' 
                visible={props.visibility} 
                showCloseIcon={false}
                footerTemplate={footerTemplate}
                isModal={true}
                // ref={dialog => dialogInstance = dialog} 
                overlayClick = {() => props.setVisibility(false)}>
                    
                    <span >Please accept below terms and use for continue using the system.</span>
                    <div className="p-3 border mt-3">
                        <p>
                        {`AI Text Analytics Tool houses MTR Corporation documents, information and communications particularly related to the business needs of Assurance Management Department for assurance purpose. Copyright of AI Text Analytics Tool and its contents is owned by MTR Corporation Limited. Any use of the AI Text Analytics Tool contents shall be governed by, inter alia, the conditions as stipulated in CGI 213 “Information Management Corporate Policy”, CGI 241 “Protection of Classified Corporation Information”, CGI 258 “Information Security Policy” and CGI 263 “Intellectual Property Management” (see `}<a href="http://portal.mtr.com.hk/s/eip/Lists/eip_cncgi/ThirdLevelDetail_CGI.aspx" target="_blank">List of CGIs</a>{`).`}
                        </p>
                        <h6><u>Disclaimer</u></h6>
                        <p>{`AI Text Analytics Tool is developed for and maintained by the Data Analytics & Intelligence (DA&I) Team of Assurance Management Department in support of assurance activities within the Corporation. Whilst endeavouring to ensure that the contents and classifications of the information on this tool are fit for use, no warranty or guarantee is given as to their completeness as content coverage will be evolving from time to time. In case of doubt, users are encouraged to contact individual content owners or document controllers for verification on specific contents. In case of any doubt, please contact any member of the Data Analytics & Intelligence (DA&I) or email to `}<a href="mailto: dai@mtr.com.hk">{`Data Analytics & Intelligence`}</a><span>.</span></p>
                    </div>
                </DialogComponent>
            </div>
        
        
        </div>
    );
}