import React, {Component} from "react";
import {createSpinner, showSpinner} from '@syncfusion/ej2-react-popups';

class LoadingComponent extends Component {
  constructor(props) {
    super(props);
    this.spinnerInstance = null;
  }

  componentDidMount() {
    if (this.spinnerInstance) {
      createSpinner({
        target: this.spinnerInstance
      });
      showSpinner(this.spinnerInstance);
    }
  }

  render() {
    return (
      <div
        ref={spinner => {
          this.spinnerInstance = spinner
        }}
      />
    )
  }
}

export default LoadingComponent;
