import React, {useState, useRef, useEffect} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent  } from '@syncfusion/ej2-react-calendars';
import moment from 'moment';
import api from "../../../lib/API";
import LinkIcon from "../../../images/Link-icon.svg";
import Modal from 'react-bootstrap/Modal';

export default function FollowedUpEdit (props) {

    const [dataSource, setDataSource] = useState(null);

    const inlineStyle = { margin: "0px 5px 0px 5px" };

    const [action, setAction] = useState('');
    const [followedUpBy, setFollowedUpBy] = useState('');
    const [followedUpDate, setFollowedUpDate] = useState('');
    const actionRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const confirmRef = useRef(null);
    
    useEffect(()=>{
        let mounted = true;
        if(props.visibility && mounted){
        }
        return () => mounted = false;
    },[]);

    const getItpReference = (itpReference) => {
        const parts = itpReference.split('#####');
        return parts[0];
    };

    const getItpItemNo = (itpReference, itpItemNo) => {
        const parts = itpReference.split('#####');
        return (parts.length > 1 ? parts[1] : itpItemNo);
    };

    const resultTemplate = () => {
        switch (props.row.predictedInspectionResult) {
            case "Endorsed":
                return <span className="text-secondary">{props.row.predictedInspectionResult}</span>
            case "Cancelled":
                return <span className="text-primary">{props.row.predictedInspectionResult}</span>
            case "Failed":
                return <span className="text-danger">{props.row.predictedInspectionResult}</span>
            case "Undetermined":
                return <span className="text-info">{props.row.predictedInspectionResult}</span>
            default:
                return <span></span>
        }
    };

    const keyTermTemplate = (row) => {
        return (
            <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield">{row.keyTerm}</span>
        );
    }

    const handleExit = () => {
      setIsConfirmed(false);
      props.setVisibility(false);
      setErrorMessage('');
      setAction('');
      setFollowedUpBy('');
      setFollowedUpDate('');
    }

    const updateFollowedBy = () => {
      const params = {
        followedUpBy: followedUpBy,
        followedUpDate: followedUpDate,
        followedUpAction: action,
      }
      console.log(params);
      api.post(`form/${props.row.form_id || props.row.formId}/followedUp`,params).then(async res => {
          props.fetchUpdate();
          handleExit();
      }, err => {
          setErrorMessage(err.response.data.message);
          setIsConfirmed(false);
      });
    }

    return (
        <div id='dialog-detail'>
            <Modal 
            show={true}
            centered={true}
            onEnter={()=>{
                setAction(props.row?.followedUpAction ?? '');
                setFollowedUpBy(props.row?.followedUpBy ?? '');
                setFollowedUpDate(props.row?.followedUpDate ?? '');
                console.log(props.row);
            }}
            minHeight="40%"
            visible={props.visibility} 
            showCloseIcon={true}
            close={()=>props.setVisibility(false)}
            header='Key Terms'
            // buttons={buttons}
            // footerTemplate={footerTemplate}
            isModal={true}
            position={{X:'center', Y:'center'}}
            // ref={dialog => dialogInstance = dialog} 
            overlayClick = {() => props.setVisibility(false)}>
                <div class="dialog-header">
                    Edit Follow Up Action
                </div>
                <div class="dialog-body">
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Link to iSuper</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Project</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Contract</p></td>
                                {/*<td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>CFD</p></td>*/}
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>RISC No</p></td>
                                <td><p className="font-weight-bold text-dark" style={inlineStyle}>ITP Reference</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>ITP Item No</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Discipline</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Location</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">
                                    <img style={{cursor:"pointer"}} src={LinkIcon} alt="" 
                                    onClick={(e)=>{
                                        e.stopPropagation();
                                        window.open(props.row.formLink, "_blank", "noopener,noreferrer");
                                    }} />
                                    </p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.project}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.contract}</p></td>
                                {/*<td><p className="mb-4 ml-1 mr-2">{props.row.cfd}</p></td>*/}
                                <td><p className="mb-4 ml-1 mr-2">{props.row.riscNo}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{getItpReference(props.row.itpReference)}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{getItpItemNo(props.row.itpReference, props.row.itpItemNo)}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.discipline}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.location}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Endorser</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Role</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Inspection Date</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Inspection Result</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Predicted Inspection Result</p></td>
                                <td><p className="font-weight-bold text-dark" style={inlineStyle}>Reasons for Prediction</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.endorser}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.role}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.inspectionDate}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.inspectionResult}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{resultTemplate()}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.reasonsForPrediction}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr class="dialog-hr" />
                <div>
                    <div class="dialog-body">
                        <table>
                            <tbody>
                                <tr>
                                    <td><p className="font-weight-bold text-dark" style={inlineStyle}>Followed Up By</p></td>
                                    <td><p className="font-weight-bold text-dark" style={inlineStyle}>Followed Up Date</p></td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "5px" }}><TextBoxComponent
                                        value={followedUpBy}
                                        placeholder="Followed Up By" width="400px" height="50px"
                                        floatLabelType="Never"
                                        input={(e)=>setFollowedUpBy(e.value)}
                                        change={(e)=>setFollowedUpBy(e.value)}
                                    /></td>
                                    <td style={{"padding-right": "5px"}}>
                                        <DatePickerComponent id="followedUpDate" value={followedUpDate} placeholder="Followed Up Date" floatLabelType="Never" width="200px" height="50px"
                                        format="yyyy-MM-dd"
                                        change={(e)=>{
                                            if (e.value) {
                                                setFollowedUpDate(moment(e.value).format('YYYY-MM-DD'));
                                            } else {
                                                setFollowedUpDate('');
                                            }
                                        }}
                                        cleared={()=>{
                                            setFollowedUpDate('');
                                        }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="font-weight-bold text-dark" style={{...inlineStyle, paddingTop: "10px", paddingBottom: "10px"}}>Followed Up Action</p>
                        <TextBoxComponent
                            ref={actionRef}
                            value={action}
                            placeholder="Followed Up Action" 
                            floatLabelType="Never"
                            width="800px"
                            input={(e)=>setAction(e.value)}
                            change={(e)=>setAction(e.value)} />
                        <div className="col-7">
                            {errorMessage && <p style={{textAlign:"left !important"}} className="float-left mt-2 text-left text-danger m-0">{errorMessage}</p>}
                        </div>
                    </div>
                </div>
                <div>
                    {
                        <div style={{paddingTop: "20px"}}>
                            <span style={{float: "right"}}>
                                <button className="btn cancel mr-2 mb-2 pl-4 pr-4" 
                                onClick={()=>handleExit()}>Cancel</button>
                                <button
                                ref={confirmRef}
                                className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                                disabled={!followedUpBy || !followedUpDate || !action}
                                onClick={()=>{
                                    setIsConfirmed(true);
                                    updateFollowedBy();
                                }}
                                >Confirm</button>
                            </span>
                        </div>
                    }
                </div> 
            </Modal>
        </div>
        
    );

}