const MAX_CONCURRENT_UPLOAD = (process.env.REACT_APP_MAX_CONCURRENT_UPLOAD || 5);
const MAX_UPLOAD_SIZE = (process.env.REACT_APP_MAX_UPLOAD_SIZE || 40 * 1024 * 1024);

const k = 1024;
const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
let i = Math.floor(Math.log(MAX_UPLOAD_SIZE) / Math.log(k));
let size = parseFloat(MAX_UPLOAD_SIZE / Math.pow(k, i)).toFixed(0)
const MAX_UPLOAD_SIZE_FORMATTED = `${size} ${units[i]}`

export { MAX_CONCURRENT_UPLOAD, MAX_UPLOAD_SIZE, MAX_UPLOAD_SIZE_FORMATTED };
