import React, {useState, useEffect, useRef} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import api from "../../../lib/API";

export default function CreateUserDialog(props){
    // let dialogInstance;
    const [rawData, setRawData] = useState();
    const [roles,setRoles] = useState();

    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [section, setSection] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [selectedItem, setSelectedItem] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);

    const dropdownsRef = useRef(null);
    const confirmButtonRef = useRef(null);
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const usernameRef = useRef(null);
    const nameRef = useRef(null);
    const sectionRef = useRef(null);

    useEffect(()=>{
        let mounted = true;
        //console.log(confirmButtonRef);
        //upload Button ref
        if(mounted && props.visibility)
            if(!isConfirmed){
                confirmButtonRef.current.removeAttribute("disabled");
                confirmButtonRef.current.innerHTML = `<span>Confirm</span>`;
            }
            else{
                confirmButtonRef.current.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-2">Confirm</span>`;
                confirmButtonRef.current.setAttribute("disabled","disabled");
            }
        return () => mounted = false;
    },[isConfirmed])

    //listening on Password & Confirm Password field
    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(password || confirmPassword){
                if(password!==confirmPassword){
                    passwordRef.current.cssClass="e-error";
                    confirmPasswordRef.current.cssClass="e-error";
                    setErrorMessage("Password does not match");
                }
                else{
                    passwordRef.current.cssClass="";
                    confirmPasswordRef.current.cssClass="";
                    setErrorMessage("");
                }
            }
            else{
                passwordRef.current.cssClass="";
                confirmPasswordRef.current.cssClass="";
                setErrorMessage("");
            }

        }
        return () => mounted = false;
    },[password,confirmPassword]);

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(usernameRef.current.cssClass === "e-error")
                usernameRef.current.cssClass = '';
        }
        return () => mounted = false;
    },[username]);

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(dropdownsRef.current.cssClass === "e-error")
                dropdownsRef.current.cssClass = '';
        }
        return () => mounted = false;
    },[selectedItem])

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(nameRef.current.cssClass === "e-error")
                nameRef.current.cssClass = '';
        }
        return () => mounted = false;
    },[name])

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(sectionRef.current.cssClass === "e-error")
                sectionRef.current.cssClass = '';
        }
        return () => mounted = false;
    },[section])

    // useEffect(()=>{
    //     let mounted = true;
    //     if(mounted && props.visibility){
    //         if(passwordRef.current.cssClass === "e-error")
    //             passwordRef.current.cssClass = '';
    //     }
    //     return () => mounted = false;
    // },[password])

    const footerTemplate = () => {
        return (
            <div className="row">
                <div className="col-7">
                    {errorMessage && <span style={{textAlign:"left !important"}} className="float-left mt-2 text-left text-danger m-0">{errorMessage}</span>}
                </div>
                <div className="col-5">
                    <button className="btn cancel mr-2 mb-2 pl-4 pr-4" 
                    onClick={()=>{
                        props.setVisibility(false);
                    }}>Cancel</button>
                    <button className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                    ref={confirmButtonRef}
                    disabled={!( username && name && selectedItem && section && (!process.env.REACT_APP_PASSWORD_LOGIN || (password && confirmPassword && (password===confirmPassword))))}
                    onClick={()=>{
                        setIsConfirmed(true);
                        const params = {
                            // roleId:rawData[rawData.findIndex(obj => {return obj.roleName === selectedItem})].roleId,
                            roleId: selectedItem,
                            username: username,
                            password: password,
                            displayName: name,
                            section: section,
                        }
                        api.post('/users', params).then(async (res) => {
                            console.log(res.data);
                            props.fetchUsers();
                            handleClick();
                        }, err => {
                            console.log(err);
                            setErrorMessage(err.response.data.message);
                            setIsConfirmed(false);
                            if(err.response.data.errorFieldList.length !== 0)
                                err.response.data.errorFieldList.forEach(element => {
                                    switch (element) {
                                        case "username":
                                            usernameRef.current.cssClass = "e-error";
                                            break;
                                        case "role":
                                            dropdownsRef.current.cssClass = "e-error";
                                            break;
                                        case "name":
                                            nameRef.current.cssClass = "e-error";
                                            break;
                                        case "section":
                                            sectionRef.current.cssClass = "e-error";
                                            break;
                                        case "password":
                                            passwordRef.current.cssClass = "e-error";
                                            break;
                                        default:
                                            break;
                                    }
                                });
                        })
                    }}>Confirm</button>
                </div>
            </div>
        );

    };
     // function to handle the close on overlay
     const handleClick = () => {
        setIsConfirmed(false);
        props.setVisibility(false);
        setUsername('');
        setName('');
        setPassword('');
        setSection('');
        setSelectedItem(props.rolesList.defaultRoleId);
        setConfirmPassword('');
        setErrorMessage('');
        passwordRef.current.cssClass="";
        confirmPasswordRef.current.cssClass="";
        usernameRef.current.cssClass = '';
        dropdownsRef.current.cssClass = '';
        nameRef.current.cssClass = '';
        sectionRef.current.cssClass = '';
        dropdownsRef.current.index = props.rolesList.roleList.findIndex(e=>{return e.roleId === props.rolesList.defaultRoleId});
        // dropdownsRef.current.clear();
        // if (args.checked) {
        //     this.dialogInstance.overlayClick = () => {
        //         this.setState({ hideDialog: false });
        //     };
        // }
        // else {
        //     this.dialogInstance.overlayClick = () => {
        //         this.setState({ hideDialog: true });
        //     };
        // }
    }
    return (
        <div>
            <div className="App" id='dialog-target'>
                <DialogComponent 
                width="40%"
                close={handleClick} 
                header='Create User' 
                visible={props.visibility} 
                showCloseIcon={false} 
                footerTemplate={footerTemplate}
                isModal={true}
                beforeOpen={()=>setSelectedItem(props.rolesList.defaultRoleId)}
                // ref={dialog => dialogInstance = dialog} 
                overlayClick = {handleClick}>
                <span className="ml-2">Please fill in below information to create new user</span>
                    <div className="textboxes mt-4">
                        <div className="row">
                            <div className="col">
                                <h6>Username</h6>
                                <TextBoxComponent ref={usernameRef} value={username} input={e=>setUsername(e.value)} change={e=>setUsername(e.value)} placeholder="Username" floatLabelType="Never" autocomplete={'username'}/>
                            </div>
                            <div className="col">
                                <h6>Role</h6>
                                <DropDownListComponent 
                                ref={dropdownsRef}
                                fields={{text:"roleName", value:"roleId"}}  
                                dataSource={props.rolesList?props.rolesList.roleList:null}
                                index={props.rolesList?props.rolesList.roleList.findIndex(e=>{return e.roleId === props.rolesList.defaultRoleId}):null}
                                select={(e)=>{
                                    if(props.visibility)
                                    setSelectedItem(e.itemData.roleId)}}
                                change={(e)=>{
                                    if(props.visibility)
                                    setSelectedItem(e.itemData.roleId)}} />
                            </div>
                        </div>
                    </div>
                    <div className="textboxes">
                        <div className="row ">
                            <div className="col ">
                                <h6>Name</h6>
                                <TextBoxComponent ref={nameRef} value={name} input={e=>setName(e.value)} change={e=>setName(e.value)} placeholder="Name" floatLabelType="Never" autocomplete={'off'}/>
                            </div>
                            <div className="col">
                                <h6>Section</h6>
                                <TextBoxComponent ref={sectionRef} value={section} input={e=>setSection(e.value)} change={e=>setSection(e.value)} placeholder="Section" floatLabelType="Never" autocomplete={'off'}/>
                            </div>
                        </div>
                    </div>
                    {
                        process.env.REACT_APP_PASSWORD_LOGIN ? <div className="textboxes">
                            <div className="row ">
                                <div className="col">
                                    <h6>Password</h6>
                                    <TextBoxComponent ref={passwordRef} type="password" value={password} input={e=>setPassword(e.value)} change={e=>setPassword(e.value)} placeholder="Password" floatLabelType="Never" autocomplete={'current-password'}/>
                                </div>
                                <div className="col">
                                    <h6>Confirm Password</h6>
                                    <TextBoxComponent ref={confirmPasswordRef} type="password" value={confirmPassword} input={e=>setConfirmPassword(e.value)} change={e=>setConfirmPassword(e.value)} placeholder="Confirm Password" floatLabelType="Never" autocomplete={'off'}/>
                                </div>
                            </div>
                        </div> : null
                    }
                    {
                        process.env.REACT_APP_PASSWORD_LOGIN ? <div className="pb-4 pt-2 text-secondary m-2">
                            <p className="m-0 content-text-inactive" style={{color:"#6F7388"}}>• Password length no shorter than 10 characters.</p>
                            <p className="m-0 content-text-inactive" style={{color:"#6F7388"}}>• Password must be set with a combination from 3 of the following:</p>
                            <p className="m-0 content-text-inactive" style={{color:"#6F7388"}}>&emsp;{`- Uppercase letters (A-Z)`}</p>
                            <p className="m-0 content-text-inactive" style={{color:"#6F7388"}}>&emsp;{`- Lowercase letters (a-z)`}</p>
                            <p className="m-0 content-text-inactive" style={{color:"#6F7388"}}>&emsp;{`- Numbers (0-9)`}</p>
                            <p className="m-0 content-text-inactive" style={{color:"#6F7388"}}>&emsp;{`- Non-alphanumeric characters (e.g., !@#$%)`}</p>
                        </div> : null
                    }
                <span className="ml-2">* All fields are mandatory.</span>
                </DialogComponent>
            </div>
        
        
        </div>
    );

};