import React,{useState, useRef, useEffect} from "react";

import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';

import UploadIcon from "../../images/Upload-icon.svg";
import api from "../../lib/API";
import { MAX_CONCURRENT_UPLOAD, MAX_UPLOAD_SIZE } from "../../limit";

export default function Uploader(props){
    const [uploading, setUploading] = useState(false);
    const ref = useRef(null);
    
    // const uploadText = React.createElement('span', null, "Upload");
    // const uploadButton = React.createElement("img", {src:UploadIcon}, null);
    // const component = React.createElement("div", null, uploadButton,uploadText);
    
    useEffect(()=>{
        let mounted = true;
        //upload Button ref
        //console.log(ref.current);
        if(mounted)
            if(!uploading){
                ref.current.browseButton.removeAttribute("disabled");
                ref.current.browseButton.innerHTML = `<img src=${UploadIcon} alt="" /><span>Upload</span>`;
                ref.current.browseButton.classList.add("btn");
                ref.current.browseButton.classList.add("btn-dark");
            }
            else{
                ref.current.browseButton.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-2">Upload</span>`;
                ref.current.browseButton.classList.add("btn");
                ref.current.browseButton.setAttribute("disabled","disabled");
            }
        return () => mounted = false;
    },[uploading])

    const onBeforeUpload = (e) => {
        // get the file size in bytes
        const sizeInBytes = e.fileData.size;
        if(sizeInBytes > MAX_UPLOAD_SIZE){
            props.setOversize(true);
        }
    };

    const onSelected = async (args) => {
        console.log(args.filesData);
        //clear previous state
        props.setOversizeArr([]);
        props.setErrorArr([]);
        props.setIsNumOfFilesExceeded(false);
        setUploading(true);
        props.setUploading(true);
        let visibility = {errorDialog:false};
        if(args.filesData.length > MAX_CONCURRENT_UPLOAD){
            visibility.errorDialog = true;
            props.setIsNumOfFilesExceeded(true);
        }
        else{
            await uploadMultipleFiles(args.filesData, visibility);
        }
        ref.current.clearAll();
        const {fetchJobs} = props;
        fetchJobs();
        setUploading(false);
        props.setUploading(false);
        props.setError(visibility.errorDialog);
    }

    const uploadFile = async (file, visibility) => {
        const {name, rawFile, type} = file;
        props.setUploadFileName(name);
        // ref.current.clearAll();
    
        const dataBody = new FormData();
        dataBody.set('file', rawFile);
        //dataBody.append('filename',name);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        if(file.size > MAX_UPLOAD_SIZE){
            visibility.errorDialog = true;
            props.setOversizeArr(prev=>[...prev,name]);
        }

        else if(type.toLowerCase() !== "csv"){
            visibility.errorDialog = true;
            props.setErrorArr(prev=>[...prev,{name:name,message:"Incorrect file format"}]);
        }
        
        else{
            await api.post('/jobs', dataBody, config)
            .then(async res => {
                console.log(res.data);
                })
            .catch( err => {
                console.log(err);
                visibility.errorDialog = true;
                if(err.response.status === 413){
                    props.setOversizeArr(prev=>[...prev,name]);
                }
                else {
                    props.setErrorArr(prev=>[...prev,{name:name,message:err.message === "Network Error"? "Connection error. Please try again.":err.response.data.message}]);
                }
            })
        }
    }


    const uploadMultipleFiles = (files, visibility) => {
        return files.reduce(async (accumulatorPromise, file) => {
                return accumulatorPromise.then(async () => {
                    if(file) await uploadFile(file, visibility);
                })
            }, Promise.resolve())
    }
    // const handleRef = (ref) => {
    //     ref.dropArea=null; 

    // }

    return (
        <>
            {<UploaderComponent 
            autoUpload={true} 
            // asyncSettings={path} 
            dropArea={null} 
            ref={ref}
            uploading={onBeforeUpload}
            maxFileSize={MAX_UPLOAD_SIZE}
            sequentialUpload={true}
            multiple={MAX_CONCURRENT_UPLOAD > 1}
            selected={onSelected}
            allowedExtensions=".csv"
            enabled={props.enabled}
            // buttons={{browse:UploadIcon+"Upload"}}
            />}
        </>
        
    );

}