import React, {useState, useRef, useEffect} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';

import { useHistory } from "react-router-dom";
import LocalStorageService from "../../../lib/LocalStorageService";


export default function LogoutDialog(props){

    const history = useHistory();
    const logout = () => {
        let path = "/login";
        props.setVisibility(false);
        LocalStorageService.clearToken();
        history.push(path);
    };

    const footerTemplate = () => {
        return(
        <div className="mb-3 mr-2">
            <button className="mr-2 btn cancel pl-4 pr-4" onClick={()=>props.setVisibility(false)}>Cancel</button>
            <button className="btn btn-dark pl-4 pr-4" onClick={logout}>Confirm</button>
        </div>)
    }

    return (
        <div>
            <div className="App" id='dialog-target'>
                <DialogComponent 
                width="30%"
                close={()=>props.setVisibility(false)} 
                header='Logout' 
                visible={props.visibility} 
                showCloseIcon={false}
                isModal={true}
                footerTemplate={footerTemplate}
                // ref={dialog => dialogInstance = dialog} 
                overlayClick = {() => props.setVisibility(false)}>
                    
                    <span className="ml-2 content-text">Are you sure to logout?</span>
                    
                </DialogComponent>
            </div>
        
        
        </div>
    );
}