import React, { useState, useRef, useEffect } from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import api from "../../../lib/API";
import LinkIcon from "../../../images/Link-icon.svg";
import CreateIcon from "../../../images/Create.svg";
import DeleteIcon from "../../../images/icon-delete-row.svg"
import Modal from 'react-bootstrap/Modal';

export default function KeyTermsEditDialog(props) {

    const [dataSource, setDataSource] = useState(null);

    const inlineStyle = { margin: "0px 5px 0px 5px" };

    const [errorMessage, setErrorMessage] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const confirmRef = useRef(null);
    const [commentList, setCommentList] = useState([]);
    const [keyTermList, setKeyTermList] = useState([]);

    const fullKeyTermList = {
        "General": [
            "Non compliance with specifications / drawings / design / standard",
            "Incorrect material type / size / pattern / colour",
            "Inspection item not found",
            "Instrument damaged",
            "No permit to work",
            "Deformation / deflection",
            "Poor workmanship",
            "wrong / missing information",
            "duplicate risc form",
            "inappropriate information",
            "re-sequence work",
            "work not ready",
            "contractor cancel inspection",
            "Unpredictable events",
            "Late Submission",
            "outstanding works",
            "outstanding submission",
        ],
        "ABWF": [
            "Blockwork & Partitions Defects",
            "Doors & Shutters Defects",
            "Facade & Canopies defects",
            "False ceiling & lightings defects",
            "Fire protectoin defects",
            "Handrails & Balustrades Defects",
            "Signage Defects",
            "Tiling / VE / Aluminum Panels defects",
            "Other ABWF Issues",
        ],
        "CS": [
            "Concrete defects",
            "Incorrect concrete grade",
            "Drainage works defects",
            "Roadwork defects",
            "Steel related defects",
            "defective coupler",
            "Civil & Structure - Water Proofing Issue",
            "Bridge & Viaduct related defects",
            "Pre-cast elemet defects",
        ],
        "FDN": [
            "Foundation defects",
        ],
        "GEO": [
            "Geotechnical Defects",
            "Observable Geotechnical Risks",
        ],
        "OHL": [
            "Overhead Line - Support Issue",
            "Overhead Line - Wire Issues",
            "Overhead Line - Switch Gear / Equipment Issue",
        ],
        "PWY": [
            "Permanent Way / Trackworks Defects",
        ],
        "EM": [
            "Inaccessibility",
            "lack of protection",
            "Support defects",
            "improper labelling",
            "E&M - Energy Related Defects",
            "Electrical Installation Related Defects",
            "Lightning System related defects",
            "Pipework & Equipment defects",
            "Water quality issue",
            "Control System defects",
            "ECS & TECS related defects",
            "Mechanical Installation defects",
            "SIG System related Defects",
            "E&M - RS related defects",
            "Communication System Defects",
            "Lifts & Escalator Defects",
            "Doors related Defects",
        ],
        "BS": [
            "Inaccessibility",
            "lack of protection",
            "Support defects",
            "improper labelling",
            "E&M - Energy Related Defects",
            "Electrical Installation Related Defects",
            "Lightning System related defects",
            "Pipework & Equipment defects",
            "Water quality issue",
            "Control System defects",
            "ECS & TECS related defects",
            "Mechanical Installation defects",
            "SIG System related Defects",
            "E&M - RS related defects",
            "Communication System Defects",
            "Lifts & Escalator Defects",
            "Doors related Defects",
        ]
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            api.get(`comment/${props.row.comment_id}/keyTerms`)
                .then(async (res) => {
                    const key_term_list = res.data.key_terms.map((item, index) => {
                        return {
                            "index": index,
                            "term_id": item.term_id,
                            "discipline": item.discipline,
                            "keyTerm": item.keyTerm,
                            "disciplineList": null,
                            "disciplineKeyTermList": null,
                            "reason": item.reasonsForAdjustment,
                            "delete": item.delete,
                            "bySystem": item.bySystem,
                        };
                    });
                    setKeyTermList(key_term_list);
                }, err => {
                    console.log(err.data)
                    setKeyTermList([]);
                })
        }
        return () => mounted = false;
    }, []);

    const getItpReference = (itpReference) => {
        const parts = itpReference.split('#####');
        return parts[0];
    };

    const getItpItemNo = (itpReference, itpItemNo) => {
        const parts = itpReference.split('#####');
        return (parts.length > 1 ? parts[1] : itpItemNo);
    };

    const resultTemplate = () => {
        switch (props.row.predictedInspectionResult) {
            case "Endorsed":
                return <span className="text-secondary">{props.row.predictedInspectionResult}</span>
            case "Cancelled":
                return <span className="text-primary">{props.row.predictedInspectionResult}</span>
            case "Failed":
                return <span className="text-danger">{props.row.predictedInspectionResult}</span>
            case "Undetermined":
                return <span className="text-info">{props.row.predictedInspectionResult}</span>
            default:
                return <span></span>
        }
    };

    const keyTermTemplate = (row) => {
        return (
            <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield">{row.keyTerm}</span>
        );
    }

    const handleExit = () => {
        setIsConfirmed(false);
        props.setVisibility(false);
        setErrorMessage('');
    }

    const commentTemplate = (row) => {
        return (
            <p dangerouslySetInnerHTML={{ __html: row.comment }} ></p>
        );
    }

    const addRow = () => {
        // Initialize disciplineList with "General" and props.row.discipline unconditionally
        const disciplineList = ["General", props.row.discipline];
    
        // Determine the discipline to use
        const discipline = disciplineList[0];
    
        // Initialize disciplineKeyTermList based on the discipline
        const disciplineKeyTermList = (fullKeyTermList[discipline] || []).concat("Other");
    
        // Determine the keyTerm to use
        const keyTerm = disciplineKeyTermList[0]; // Use "Other" as default if no key terms are available
    
        // Add the new row to keyTermList
        setKeyTermList([...keyTermList, {
            "index": keyTermList.length,
            "term_id": 0,
            "discipline": discipline,
            "keyTerm": keyTerm,
            "disciplineList": disciplineList,
            "disciplineKeyTermList": disciplineKeyTermList,
            "reason": "",
            "delete": false,
            "isManualAdded": true
        }]);
    }
    const updateDiscipline = (index, discipline) => {
        let disciplineKeyTermList = fullKeyTermList[discipline] || [];
        disciplineKeyTermList = [...disciplineKeyTermList, "Other"];
        const keyTerm = disciplineKeyTermList[0] 
        setKeyTermList([
            ...keyTermList.slice(0, index),
            {
                ...keyTermList[index],
                "discipline": discipline,
                "keyTerm": keyTerm,
                "disciplineKeyTermList": disciplineKeyTermList,
            },
            ...keyTermList.slice(index + 1),
        ]);
    }

    const updateDisciplineKeyTerm = (index, disciplineKeyTerm) => {
        setKeyTermList([
            ...keyTermList.slice(0, index),
            {
                ...keyTermList[index],
                "keyTerm": disciplineKeyTerm,
            },
            ...keyTermList.slice(index + 1),
        ]);
    }

    const updateReason = (index, reason) => {
        setKeyTermList([
            ...keyTermList.slice(0, index),
            {
                ...keyTermList[index],
                "reason": reason,
            },
            ...keyTermList.slice(index + 1),
        ]);
    }

    const updateDelete = (index, _delete) => {
        setKeyTermList([
            ...keyTermList.slice(0, index),
            {
                ...keyTermList[index],
                "delete": _delete,
            },
            ...keyTermList.slice(index + 1),
        ]);
    }

    const updateKeyTerm = () => {
        const params = {
            "comment_id": props.row.comment_id,
            "keyTermList": keyTermList.filter(item => item.term_id !== 0 || !item.delete).map(item => {
                return {
                    "term_id": item.term_id,
                    "keyTerm": item.keyTerm,
                    "reasonsForAdjustment": item.reason,
                    "delete": item.delete,
                };
            }),
        };
        console.log(params);
        api.post(`jobs/${props.jobID}/keyTerm`, params).then(async res => {
            console.log(res.data);
            props.fetchUpdate();
            handleExit();
        }, err => {
            setErrorMessage(err.response.data.message);
            console.log(err);
            setIsConfirmed(false);
        });
    }

    return (
        <div id='dialog-detail'>
            <Modal
                show={true}
                centered={true}
                onEnter={() => {
                    setCommentList([{
                        ...props.row,
                        "comment": props.row.comment.replace(/<span class="highlighted-comment">/g, "").replace(/<\/span>/g, ""),
                    }]);
                }}
                minHeight="40%"
                visible={props.visibility}
                showCloseIcon={true}
                close={() => props.setVisibility(false)}
                header='Key Terms'
                // buttons={buttons}
                // footerTemplate={footerTemplate}
                isModal={true}
                position={{ X: 'center', Y: 'center' }}
                // ref={dialog => dialogInstance = dialog} 
                overlayClick={() => props.setVisibility(false)}>
                <div className={"dialog-header"}>
                    Key Terms
                </div>
                <div className={"dialog-body"}>
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Link to iSuper</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Project</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Contract</p></td>
                                {/*<td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>CFD</p></td>*/}
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>RISC No</p></td>
                                <td><p className="font-weight-bold text-dark" style={inlineStyle}>ITP Reference</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>ITP Item No</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Discipline</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Location</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">
                                    <img style={{ cursor: "pointer" }} src={LinkIcon} alt=""
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            window.open(props.row.formLink, "_blank", "noopener,noreferrer");
                                        }} />
                                </p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.project}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.contract}</p></td>
                                {/*<td><p className="mb-4 ml-1 mr-2">{props.row.cfd}</p></td>*/}
                                <td><p className="mb-4 ml-1 mr-2">{props.row.riscNo}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{getItpReference(props.row.itpReference)}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{getItpItemNo(props.row.itpReference, props.row.itpItemNo)}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.discipline}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.location}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Endorser</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Role</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Inspection Date</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Inspection Result</p></td>
                                <td><p className="font-weight-bold text-dark" style={{ minWidth: "300px" }}></p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.endorser}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.role}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.inspectionDate}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.inspectionResult}</p></td>
                                <td><p className="mb-4 ml-1 mr-2"></p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <GridComponent
                    dataSource={commentList}
                    height='100%'
                    allowTextWrap={true}
                >
                    <ColumnsDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="300px" field='commentField' headerText='Comment Field'></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="" field='comment' headerText='Comment'></ColumnDirective>
                    </ColumnsDirective>
                </GridComponent>
                <hr class="dialog-hr" />
                <div>
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Discipline</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Key Term</p></td>
                                <td><p className="font-weight-bold text-dark" style={inlineStyle}>Reason</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Delete</p></td>
                            </tr>
                            {
                                keyTermList && keyTermList.filter(item => item.bySystem).map(item =>
                                    <tr>
                                        <td style={{ padding: "10px", minWidth: "210px", verticalAlign: "top" }}>
                                            {item.discipline}
                                        </td>
                                        <td style={{ padding: "10px", minWidth: "310px", verticalAlign: "top" }}>
                                            {item.keyTerm}
                                        </td>
                                        <td style={{ padding: "5px", verticalAlign: "top" }}>
                                            <TextBoxComponent
                                                value={item.reason}
                                                placeholder="Reason" width="500px" height="50px"
                                                floatLabelType="Never"
                                                disabled={!item.delete || props.row.isIncorporated}
                                                input={(e) => updateReason(item.index, e.value)}
                                                change={(e) => updateReason(item.index, e.value)}
                                                style={{
                                                    opacity: !item.delete || props.row.isIncorporated ? null : 1,
                                                    backgroundColor: item.delete ? 'white' : '#ebebeb',
                                                    cursor: item.delete ? 'not-allow' : 'text',
                                                }}
                                            />
                                        </td>
                                        <td style={{ textAlign: "center", verticalAlign: "top", paddingTop: "12px" }}>
                                            <CheckBoxComponent
                                                checked={item.delete}
                                                disabled={props.row.isIncorporated}
                                                change={(e) => updateDelete(item.index, e.checked)}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                keyTermList && keyTermList.filter(item => !item.bySystem && item.term_id !== 0 && !item.delete).map(item =>
                                    <tr>
                                        <td style={{ padding: "10px", minWidth: "210px", verticalAlign: "top" }}>
                                            {item.discipline}
                                        </td>
                                        <td style={{ padding: "10px", minWidth: "310px", verticalAlign: "top" }}>
                                            {item.keyTerm}
                                        </td>
                                        <td style={{ padding: "5px", verticalAlign: "top" }}><TextBoxComponent
                                            value={item.reason}
                                            placeholder="Reason" width="500px" height="50px"
                                            floatLabelType="Never"
                                            disabled={props.row.isIncorporated}
                                            input={(e) => updateReason(item.index, e.value)}
                                            change={(e) => updateReason(item.index, e.value)}
                                        /></td>
                                        <td style={{ textAlign: "center", verticalAlign: "top" }}><img
                                            style={{ cursor: props.row.isIncorporated ? "default" : "pointer", filter: props.row.isIncorporated ? "opacity(0.5)" : "none", width: "30px", paddingLeft: "6px", paddingTop: "12px" }}
                                            src={DeleteIcon}
                                            alt=""
                                            onClick={props.row.isIncorporated ? null : (e) => {
                                                updateDelete(item.index, true)
                                            }}
                                        /></td>
                                    </tr>
                                )
                            }
                            {
                                keyTermList && keyTermList.filter(item => !item.bySystem && item.term_id === 0 && !item.delete).map(item =>
                                    <tr>
                                        <td style={{ padding: "5px" }}><DropDownListComponent
                                            placeholder="Discipline" width="200px" height="50px"
                                            dataSource={item.disciplineList}
                                            value={item.discipline}
                                            disabled={props.row.isIncorporated}
                                            change={(e) => {
                                                updateDiscipline(item.index, e.value);
                                            }}
                                        /></td>
                                        <td style={{ padding: "5px" }}><DropDownListComponent
                                            placeholder="Key Term" width="300px" height="50px"
                                            dataSource={item.disciplineKeyTermList}
                                            value={item.keyTerm}
                                            disabled={props.row.isIncorporated}
                                            change={(e) => {
                                                updateDisciplineKeyTerm(item.index, e.value);
                                            }}
                                        /></td>
                                        <td style={{ padding: "5px" }}><TextBoxComponent
                                            value={item.reason}
                                            placeholder="Reason" width="500px" height="50px"
                                            floatLabelType="Never"
                                            disabled={props.row.isIncorporated}
                                            input={(e) => updateReason(item.index, e.value)}
                                            change={(e) => updateReason(item.index, e.value)}
                                        /></td>
                                        <td style={{ textAlign: "center", verticalAlign: "top" }}><img
                                            style={{ cursor: props.row.isIncorporated ? "default" : "pointer", filter: props.row.isIncorporated ? "opacity(0.5)" : "none", width: "30px", paddingLeft: "6px", paddingTop: "12px" }}
                                            src={DeleteIcon}
                                            alt=""
                                            onClick={props.row.isIncorporated ? null : (e) => {
                                                updateDelete(item.index, true)
                                            }}
                                        /></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    {
                        props.row.isIncorporated
                            ?
                            <div style={{ paddingTop: "20px", float: "right" }}>
                                <button className="btn cancel mr-2 mb-2 pl-4 pr-4"
                                    onClick={() => handleExit()}>Close</button>
                            </div>
                            :
                            <>
                                <div style={{ paddingTop: "20px" }}>
                                    <button
                                        className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                                        onClick={() => addRow()}><img src={CreateIcon} alt="" /> Add</button>
                                </div>
                                <div className="col-7">
                                    {errorMessage && <p style={{ textAlign: "left !important" }} className="float-left mt-2 text-left text-danger m-0">{errorMessage}</p>}
                                </div>
                                <div style={{ paddingTop: "20px", float: "right" }}>
                                    <button className="btn cancel mr-2 mb-2 pl-4 pr-4"
                                        onClick={() => handleExit()}>Cancel</button>
                                    <button
                                        ref={confirmRef}
                                        className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                                        disabled={keyTermList && !keyTermList.every(item => !(!item.reason && ((item.bySystem && item.delete) || (!item.bySystem && !item.delete))))}
                                        onClick={() => {
                                            setIsConfirmed(true);
                                            updateKeyTerm();
                                        }}
                                    >Confirm</button>
                                </div>
                            </>
                    }
                </div>
            </Modal>
        </div>

    );

}
