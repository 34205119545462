import React, { useRef, useState, useEffect } from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import api from "../../lib/API";
import { useLocation, useParams, useHistory } from "react-router-dom"
import EditIcon from "../../images/Edit-icon.svg";
import DeletedPhraseIcon from "../../images/Deleted-Phrase.svg";

import KeyTermRepositoryEdit from "./Dialog/KeyTermRepositoryEdit";
import LocalStorageService from "../../lib/LocalStorageService";
import "./style.css";

export default function Pending (props) {
    const permission = LocalStorageService.getPermissions();
    const history = useHistory()
    const [params, setParams] = useState(1);
    const [dataSource, setDataSource] = useState(null);
    const [totalRecord, setTotalRecord] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [pageCount, setPageCount] = useState(5);

    const dt = useRef(null);
    const [detailClicked, setDetailClicked] = useState(false);
    const [rowInfo, setRowInfo] = useState({});
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            api.get(`keyTermDiscovery/keyTermRepository`)
                .then(async (res) => {
                    const phraseList = res.data.phraseList;
                    setDataSource(phraseList);
                }, err => {
                    console.log(err.data)
                    setDataSource(null);
                })
        }

        return () => mounted = false;
    }, [params])

    const fetchUpdate = () => {
        api.get(`keyTermDiscovery/keyTermRepository`)
            .then(async (res) => {
                const phraseList = res.data.phraseList;
                setDataSource(phraseList);
            }, err => {
                console.log(err.data)
                setDataSource(null);
            })
    }

    const pagerTemplate = () => {
        return (
            <PagerComponent
                totalRecordsCount={totalRecord}
                pageSize={pageSize}
                pageCount={pageCount}
                enableQueryString={true}
                enablePersistence={true}
                click={() =>
                    setParams(window.location.href.slice(window.location.href.search('page')))}></PagerComponent>
        )
    }

    const editTemplate = (row) => {
        return (
            <img src={EditIcon} alt="" 
                onClick={(e)=>{
                    e.stopPropagation();
                    // setRowInfo(row);
                    // setShowEdit(true);
                }}
            />
        );
    }

    const phraseTemplate = (row) => {
        return (
            <span >
                {row.isAdjusted ? <span className="red-dot"></span> : <span className="empty-dot"></span>}
                <img style={{cursor:"pointer"}} src={EditIcon} alt="" 
                    onClick={(e)=>{
                        e.stopPropagation();
                        setRowInfo(row);
                        setShowEdit(true);
                    }} />
                <span className={row.isDeleted ? 'deleted-phrase' : ''}>
                    {row.phrase}
                </span>
            </span>
        );
    }

    const keyTermTemplate = (row) => {
        return (
            <span className="keytermcolumn">
                <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield-long">{row.keyTerm}</span>
            </span>  
        );
    }

    const newDisciplineTemplate = (row) => {
        return row.modifiedDiscipline ? row.modifiedDiscipline : "-";
    }

    const newKeyTermTemplate = (row) => {
        return (
            row.modifiedKeyTerm
            ?
            <span className="keytermcolumn">
                {row.newKeyTerm ? "Other" : null} <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield-long">{row.modifiedKeyTerm}</span>
            </span>
            :
            "-"
        );
    }

    const lastModifiedReasonTemplate = (row) => {
        return row.modifiedReason ? row.modifiedReason : "-";
    }

    const lastModifiedByTemplate = (row) => {
        return row.modifiedBy ? row.modifiedBy : "-";
    }

    const lastModifiedTimeTemplate = (row) => {
        return row.modifiedDate ? row.modifiedDate : "-";
    }

    return (
        <>
            <div className='control-pane'>
                <div className='control-section' >
                    <div className="float-left">
                        <span className="legend-item"><span className="red-dot"></span> Manual Adjusted (to be effective after next offline model re-training)</span>
                        <span className="legend-item"><img src={DeletedPhraseIcon} alt="" />Deleted Phrase</span>
                    </div>
                    <GridComponent
                        height='100%'
                        allowPaging={true}
                        allowTextWrap={true}
                        allowSorting={true}
                        ref={dt}
                        //queryString={true}
                        dataSource={dataSource}
                        pageSettings={{ enableQueryString: true, pageSize: 50, pageCount: 5 }} >
                        <ColumnsDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="200px" field='phrase' headerText='Phrase' template={phraseTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='discipline' headerText='Discipline'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="200px" field='keyTerm' headerText='Key Term' template={keyTermTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='modifiedDiscipline' headerText='New Discipline' template={newDisciplineTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="200px" field='modifiedKeyTerm' headerText='New Key Term' template={newKeyTermTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='lastModifiedReason' headerText='Last Modified Reason' template={lastModifiedReasonTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='lastModifiedBy' headerText='Last Modified By' template={lastModifiedByTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='lastModifiedTime' headerText='Last Modified Time' template={lastModifiedTimeTemplate}></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Page]} />

                    </GridComponent>
                </div>
            </div>
            {
                showEdit && <KeyTermRepositoryEdit
                    setVisibility={setShowEdit}
                    row={rowInfo}
                    fetchUpdate={fetchUpdate}
                />
            }
        </>
    );
}