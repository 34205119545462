import React, {useState, useEffect, useRef} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import api from "../../../lib/API";

export default function ChangePasswordDialog (props) {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const confirmRef = useRef(null);
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);

    const handleExit = () => {
        passwordRef.current.cssClass="";
        confirmPasswordRef.current.cssClass="";
        setErrorMessage('');
        setIsConfirmed(false);
        props.setVisibility(false);
        setNewPassword('');
        setConfirmPassword('');
    }

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(!isConfirmed){
                confirmRef.current.removeAttribute("disabled");
                confirmRef.current.innerHTML = `<span>Confirm</span>`;
            }
            else{
                confirmRef.current.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-2">Confirm</span>`;
                confirmRef.current.setAttribute("disabled","disabled");
            }
        }
        return () => mounted = false;
    },[isConfirmed])

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(newPassword || confirmPassword){
                if(newPassword!==confirmPassword){
                    passwordRef.current.cssClass="e-error";
                    confirmPasswordRef.current.cssClass="e-error";
                    setErrorMessage("Password does not match");
                }
                else{
                    passwordRef.current.cssClass="";
                    confirmPasswordRef.current.cssClass="";
                    setErrorMessage("");
                }
            }
            else{
                passwordRef.current.cssClass="";
                confirmPasswordRef.current.cssClass="";
                setErrorMessage("");
            }

        }
        return () => mounted = false;
    },[newPassword,confirmPassword])

    const putPassword = () => {
        const params = {password:newPassword};
        const userId = props.dataSource[props.dataSource.findIndex(e=>{return e.username === props.row.username})].userId;
        api.put(`/users/${userId}/password`,params)
        .then(async (res) => {
            console.log(res.data);
            handleExit();
        }, err => {
            setErrorMessage(err.response.data.message);
            console.log(err);
            setIsConfirmed(false);
            if(err.response.data.errorFieldList.length !== 0)
                err.response.data.errorFieldList.forEach(element => {
                    switch (element) {
                        case "password":
                            passwordRef.current.cssClass = "e-error";
                            break;
                        default:
                            break;
                    }
                });
        })
    }

    const footerTemplate = () => {
        return (
            <div className="row">
                <div className="col-7">
                    {errorMessage && <p style={{textAlign:"left !important"}} className="float-left mt-2 text-left text-danger m-0">{errorMessage}</p>}
                </div>
                <div className="col-5">
                    <button
                    className="btn cancel mr-2 mb-2 pl-4 pr-4"
                    onClick={()=>handleExit()}>Cancel</button>
                    <button
                    ref={confirmRef}
                    className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                    disabled={!(newPassword && confirmPassword && (newPassword===confirmPassword))}
                    onClick={()=>{
                        setIsConfirmed(true);
                        putPassword();
                    }}>Confirm</button>
                </div>
            </div>
        );

    };

    return (
    <>
        <div className="App" id='dialog-target'>
                <DialogComponent
                width="40%"
                close={()=>handleExit()}
                header='Change Password'
                visible={props.visibility}
                showCloseIcon={false}
                // buttons={buttons}
                footerTemplate={footerTemplate}
                isModal={true}
                // ref={dialog => dialogInstance = dialog}
                overlayClick = {()=>handleExit()}>
                <div className="p-2">
                    <p className="m-0 font-weight-bold">Username</p>
                    <span className="">{props.row.username}</span>

                    <div className="mt-4">
                        <div className="row">
                            <div className="col">
                                <p className=" font-weight-bold m-0">New Password</p>
                                <TextBoxComponent ref={passwordRef} type="password" value={newPassword} input={e=>setNewPassword(e.value)} placeholder="New Password" floatLabelType="Never"/>
                            </div>
                            <div className="col">
                                <p className=" font-weight-bold m-0">Confirm New Password</p>
                                <TextBoxComponent ref={confirmPasswordRef} type="password" value={confirmPassword} input={e=>setConfirmPassword(e.value)} placeholder="Confirm New Password" floatLabelType="Never"/>
                            </div>
                        </div>
                    </div>

                    <div className="pb-4 pt-2 text-secondary m-2">
                        <p className="m-0 content-text-inactive" style={{color:"#6F7388"}}>• Password length no shorter than 10 characters.</p>
                        <p className="m-0 content-text-inactive" style={{color:"#6F7388"}}>• Password must be set with a combination from 3 of the following:</p>
                        <p className="m-0 content-text-inactive" style={{color:"#6F7388"}}>&emsp;{`- Uppercase letters (A-Z)`}</p>
                        <p className="m-0 content-text-inactive" style={{color:"#6F7388"}}>&emsp;{`- Lowercase letters (a-z)`}</p>
                        <p className="m-0 content-text-inactive" style={{color:"#6F7388"}}>&emsp;{`- Numbers (0-9)`}</p>
                        <p className="m-0 content-text-inactive" style={{color:"#6F7388"}}>&emsp;{`- Non-alphanumeric characters (e.g., !@#$%)`}</p>
                    </div>
                </div>
                </DialogComponent>
            </div>
    </>);

}