import axios from 'axios';
import LocalStorageService from "./LocalStorageService";
import moment from 'moment';

// LocalstorageService
const localStorageService = LocalStorageService.getService();

let lastActiveDatetime = moment();

const instance = axios.create({
  baseURL: (process.env.REACT_APP_API_URL || '/api'),
  responseType: 'json',
  headers: {
    'content-type': 'application/json',
  }
});

// Add a request interceptor
instance.interceptors.request.use(
  config => {
    let token;
    if (config.url === "/auth/refresh") {
      token = localStorageService.getRefreshToken();
    } else {
      token = localStorageService.getAccessToken();
    }
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    if (config.url !== "/auth/refresh" && !config.url.startsWith("/jobs?auto=true")) {
      lastActiveDatetime = moment();
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    return Promise.reject(error)
  });

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return response
  }, error => {
    const originalRequest = error.config;

    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(error));
        };
        reader.onerror = () => {
          reject(error);
        };
        reader.readAsText(error.response.data);
      });
    }

    if (error.response) {
      if (error.response.status === 401 && originalRequest.url === '/auth/token') {
        localStorageService.clearToken();
        return Promise.reject(error);
      }
      if (error.response.status === 401 && originalRequest.url.startsWith('/auth/identity')) {
        localStorageService.clearToken();
        return Promise.reject(error);
      }
      if (error.response.status === 401 && originalRequest.url === '/auth/refresh') {
        localStorageService.clearToken();
        window.location.href = '/login';
        return Promise.reject(error);
      }
      if (error.response.status === 401 && !originalRequest._retry) {
        if (moment().diff(lastActiveDatetime, 'seconds') > (process.env.REACT_APP_IDLE_TIMEOUT || 1800)) {
          localStorageService.clearToken();
          window.location.href = '/login';
          return Promise.reject(error);
        } else {
          originalRequest._retry = true;
          //const refreshToken = localStorageService.getRefreshToken();
          //return instance.post('/auth/refresh', {"refresh": refreshToken})
          return instance.post('/auth/refresh')
          .then(res => {
            if (res.status === 200) {
              localStorageService.setToken(res.data);
              instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
              return instance(originalRequest);
            }
          })
        }
      }
    }
    return Promise.reject(error);
  });

export default {
  get: (url, config = {}) => instance.get(url, config),
  delete: (url, config = {}) => instance.delete(url, config),
  post: (url, data = {}, config = {}) => instance.post(url, data, config),
  patch: (url, data = {}, config) => instance.patch(url, data, config),
  put:(url, data = {}, config = {}) => instance.put(url, data, config),
  getLastActiveDatetime: () => lastActiveDatetime,
}
