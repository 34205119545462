import React, {useEffect, useState, useRef} from "react";
import { useLocation, useHistory } from "react-router-dom";
import "../../css/layout.css";
import "./style.css"
import { TabComponent } from '@syncfusion/ej2-react-navigations';
import KeyTerms from "./KeyTerms";
import IncorrectEndorsement from "./IncorrectEndorsement";
import FormList from "./FormList";

import CloseIcon from "../../images/Close-icon.svg";
import DownloadIcon from "../../images/Download-button.svg";
import api from "../../lib/API";

export default function JobDetails(){
    const location = useLocation();
    const props = location.state;
    const history = useHistory();
    const downloadButton = useRef(null);
    const resultTab = useRef(null);

    if(!props){
        history.replace('/');
    }
    const [isDownloading, setIsDownloading] = useState(false);
    const [isShowingFormList, setIsShowingFormList] = useState(false);
    //console.log(location.pathname);
    const downloadCSV = (blob, fileName="file.zip" ) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        link.remove();
        setIsDownloading(false);
    };

    useEffect(()=>{
        let mounted = true;
        if(mounted){
            resultTab.current.animation.previous = { effect: "None" };
            resultTab.current.animation.next = { effect: "None" };
            if(!isDownloading){
                downloadButton.current.removeAttribute("disabled");
                downloadButton.current.innerHTML = `<img src=${DownloadIcon} alt="" />Download`;
            }
            else{
                downloadButton.current.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-2">Download</span>`;
                downloadButton.current.setAttribute("disabled","disabled");
            }
        }

        return () => mounted = false;
    },[isDownloading])
    
    useEffect(()=>{
        let e = document.getElementsByClassName("e-tooltip-wrap e-popup e-lib e-control e-popup-open");
        for(let i = 0; i < e.length; i++ ){
            e[i].style.display = "none";
        }

    },[])

    const onDownload = async (jobID, fileName) => {
        if(jobID && !isDownloading){
            await api.get(`/jobs/${jobID}/report`,{responseType: 'blob'})
            .then(res =>{
              if (res.status === 200 && res.data) {
                //console.log(res.data);
                fileName = fileName.replace(".csv",'');
                let disposition = res.headers["content-disposition"];
                let idx = disposition.indexOf("filename=");
                if (idx > 0) {
                    fileName = disposition.substring(idx+"filename=".length);
                    fileName = fileName.replaceAll('"', '')
                }
                downloadCSV(res.data, fileName); 
              }else{
                console.log("download failed");
              }
            }).catch(error => {
              console.log(JSON.stringify(error.response.data));
            });
        }
      }

    return (props?
        <div className="content">
            
                
            <div className="page-content">
            <div className="row">
                    <div className="col-sm-auto ">
                        <button type="button" 
                        className="btn btn-dark p-2 pb-3 pt-3 mr-2" 
                        onClick={()=>history.push('/')}
                        id="iconButton"><img className="p-0" src={CloseIcon} alt="" /></button>
                    </div>
                    <div className="col">
                        <div className="row">
                            <h5 className=" font-weight-bold">{props.filename}</h5>
                        </div>
                        <div className="row" >
                            {/* <p> <span className="font-weight-bold mr-2 text-dark">Job ID</span><span className="mr-5">{' '}{props.jobId} </span>
                                <span className="font-weight-bold mr-2 text-dark">Uploaded By</span><span className="mr-5">{' '}{props.uploadedBy}{' '}</span>
                                <span className="font-weight-bold mr-2 text-dark">Uploaded Time</span><span className="mr-5">{' '}{props.uploadedTime}{' '}</span>
                                <span className="font-weight-bold mr-2 text-dark">Completed Time</span><span className="mr-5">{' '}{props.completionTime}{' '}</span></p> */}
                            <div className=""><span className="font-weight-bold text-dark">Job ID</span></div>
                            <div className="col-2">{props.jobId}</div>
                            <div className=""><span className="font-weight-bold text-dark">Uploaded By</span></div>
                            <div className="col-2">{props.uploadedBy}</div>
                            <div className=""><span className="font-weight-bold text-dark">Uploaded Time</span></div>
                            <div className="col-2">{props.uploadedTime}</div>
                            <div className=""><span className="font-weight-bold text-dark">Completed Time</span></div>
                            <div className="col-2">{props.completionTime}</div>

                        </div>
                    </div>
                    <div className="col-2" style={{whiteSpace: "nowrap"}}>
                        <button
                        type="button" 
                        className="btn btn-dark float-right"
                        onClick={(e)=>{
                            e.preventDefault();
                            setIsShowingFormList(true);}}>RISC Form List</button>
                    </div>
                    <div className="col-1" style={{whiteSpace: "nowrap"}}>
                        <button
                        ref={downloadButton}
                        type="button" 
                        className="btn btn-dark float-right"
                        onClick={(e)=>{
                            setIsDownloading(true);
                            e.preventDefault();
                            onDownload(props.jobId, props.filename);}}><img src={DownloadIcon} alt="" /> Download</button>
                    </div>
                </div>
            

            <hr />
                {!isShowingFormList && <TabComponent id='defaultTab' ref={resultTab}>
                    <div className="e-tab-header">
                        <div className="p-3"> Key Terms </div>
                        <div className=""> Incorrect Endorsement </div>
                    </div>
                    <div className="e-content border pt-3 pr-3 pb-3">
                        <div>
                            <KeyTerms jobId={props.jobId} />
                        </div>
                            
                        <div>
                            <IncorrectEndorsement jobId={props.jobId} />
                        </div>
                    </div>
                </TabComponent>
                }
                {isShowingFormList && <FormList
                    jobId={props.jobId}
                    setVisibility={setIsShowingFormList}
                />}
            </div>


        </div>
        :null
    );

};