import React, {useState} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';

import { MAX_CONCURRENT_UPLOAD, MAX_UPLOAD_SIZE_FORMATTED } from "../../../limit";

export default function ErrorDialog (props) {

    const {fetchJobs} = props;
    const footerTemplate = () => {
        return <button 
        className="btn btn-dark pl-3 pr-3 mb-2 mr-2"
        onClick={handleExit}>Confirm</button>
    }

    const handleExit = () => {
        props.setVisibility(false);
        props.setDeleteError('');
        fetchJobs();
    }


    return (
        <div id='dialog-target'>
            <DialogComponent 
            width="30%"
            close={handleExit} 
            header='Error' 
            visible={props.visibility} 
            showCloseIcon={false}
            footerTemplate={footerTemplate}
            isModal={true}
            // ref={dialog => dialogInstance = dialog} 
            overlayClick = {handleExit}>
                {
                    props.errorArr.map((e)=>{
                        return <p className="m-0 ml-2">{`Failed to upload file "${e.name}". ${e.message? e.message : ''}`}</p>
                    })
                }
                {
                    props.oversizeArr.map((e)=>{
                        return <p className="m-0 ml-2">{`Failed to upload file "${e}". File size exceeds the limit (${MAX_UPLOAD_SIZE_FORMATTED}).`}</p>
                    })
                }
                {
                    props.isNumOfFilesExceeded && <p className="m-0 ml-2">Cannot upload more than {MAX_CONCURRENT_UPLOAD} files at the same time.</p>
                }
                {
                    props.deleteError && <p className="m-0 ml-2">{props.deleteError}</p>
                }
            </DialogComponent>
        </div>
    );

}