import React, {useState, useEffect, useRef} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import api from "../../../lib/API";


export default function DeleteDialog (props) {
    const {fetchUsers} = props;
    const [isConfirmed, setIsConfirmed] = useState(false);
    const confirmRef = useRef(null);
    //console.log(confirmRef.current);
    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(!isConfirmed){
                confirmRef.current.removeAttribute("disabled");
                confirmRef.current.innerHTML = `<span>Confirm</span>`;
            }
            else{
                confirmRef.current.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-2">Confirm</span>`;
                confirmRef.current.setAttribute("disabled","disabled");
            }
        }
        return () => mounted = false;
    },[isConfirmed])

    const footerTemplate = () => {
        return( 
        <>
            <button className="btn cancel mr-2 mb-2 pl-4 pr-4"
            onClick={()=>props.setVisibility(false)}>Cancel</button>
            <button
            ref={confirmRef}
            className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
            onClick={()=>{
                setIsConfirmed(true);
                deleteUser();
            }}>Confirm</button>
        </>
        );
    }

    const deleteUser = () => {
        const userId = props.dataSource[props.dataSource.findIndex(e=>{return e.username === props.row.username})].userId;
        api.delete(`/users/${userId}`).then(res => {
            setIsConfirmed(false);
            props.setVisibility(false);
            console.log(res.data);
            fetchUsers();
        }, err => {
            props.setDeleteError(err.response.data.message)
            setIsConfirmed(false);
            props.setVisibility(false);
            props.setErrorVisibility(true);
            console.log(err);
        })
    }

    return (
        <div id='dialog-target'>
            <DialogComponent 
            width="30%"
            close={()=>props.setVisibility(false)}
            header='Delete User'
            visible={props.visibility}
            showCloseIcon={false}
            footerTemplate={footerTemplate}
            isModal={true}
            // ref={dialog => dialogInstance = dialog} 
            overlayClick = {() => props.setVisibility(false)}>
                <span className="ml-2">{`The selected user (Username: ${props.row.username}) will be deleted. Confirm to delete?`}</span>

            </DialogComponent>
        </div>
        
    );

}