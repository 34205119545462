import React, {useState, useRef, useEffect} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import api from "../../../lib/API";

import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { Button } from "react-bootstrap";


export default function DeleteDialog (props) {
    const deleteRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [duration, setDuration] = useState(100000);
    const {fetchJobs} = props;

    const onProgress = (args) => {
        if (!isLoading) {
            args.percent = 100;
        }
    };

    const onDelete = () => {
        //console.log(deleteRef.current);
        deleteRef.current.start();
        api.delete(`/jobs/${props.jobId}`)
        .then(res => {
            console.log(res.data);
            fetchJobs();
            setIsLoading(false);
            props.setVisibility(false);
        }, err => {
            setIsLoading(false);
            props.setDeleteError(err.response.data.message);
            props.setError(true);
            props.setVisibility(false);
            console.log(err);
        })
    }

    const footerTemplate = () => {
        return (
            <div>
                <button className="btn cancel pl-3 pr-3 mr-2 mb-2" onClick={()=>props.setVisibility(false)}>Cancel</button>
                <ProgressButtonComponent
                duration={duration}
                ref={deleteRef}
                cssClass="btn btn-dark m-0 mb-2 mr-2 pl-3 pr-3"
                onClick={onDelete}
                progress={onProgress}>Confirm</ProgressButtonComponent>
    
            </div>
            
        )
    }

    return (
        <div id='dialog-target'>
            <DialogComponent 
            width="30%"
            close={()=>props.setVisibility(false)} 
            header='Delete' 
            visible={props.visibility} 
            showCloseIcon={false} 
            footerTemplate={footerTemplate}
            isModal={true}
            // ref={dialog => dialogInstance = dialog} 
            overlayClick = {() => props.setVisibility(false)}>
                <span className="ml-2">{`The selected item (Job ID:${props.jobId}) will be deleted. Confirm to delete?`}</span>
            </DialogComponent>
        </div>
        
    );

}