import React, {useState, useEffect} from 'react'
import  { Redirect, Route, useHistory } from 'react-router-dom'
import localStorageService from './lib/LocalStorageService';
import api from './lib/API';

const PrivateRoute  = ({ component: Component, ...rest }) => {
    const token = localStorageService.getAccessToken();
    const history = useHistory();
    const permission = localStorageService.getPermissions();

    
    return (
        <Route
          {...rest}
          render={props =>{
            if(token && rest.path === "/user-management"){
              if(permission.search("USER_MANAGEMENT") !== -1){
                return <Component {...props} />
              }
              else history.goBack();
            }
            else
              return token && rest.path !== "/user-management" ? (
                <Component {...props} />
              ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
              )}
          }
        />
      )
}

export default PrivateRoute;