import React, { useState, useRef, useEffect } from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import api from "../../../lib/API";
import LinkIcon from "../../../images/Link-icon.svg";

export default function DetailDialog(props) {

    const [dataSource, setDataSource] = useState(null);

    const inlineStyle = { margin: "0px 5px 0px 5px" };

    useEffect(() => {
        let mounted = true;
        if (props.visibility && mounted) {
            console.log(props.row);
            api.get(`jobs/${props.row.jobId}/keyTerms/${props.row.formId}`)
                .then(async (res) => {
                    const key_terms = res.data.key_terms;
                    setDataSource(key_terms);
                }, err => {
                    console.log(err.data)
                    setDataSource(null);
                })
        }
        return () => mounted = false;
    }, []);

    const getItpReference = (itpReference) => {
        const parts = itpReference.split('#####');
        return parts[0];
    };

    const getItpItemNo = (itpReference, itpItemNo) => {
        const parts = itpReference.split('#####');
        return (parts.length > 1 ? parts[1] : itpItemNo);
    };

    const resultTemplate = () => {
        switch (props.row.predictedInspectionResult) {
            case "Endorsed":
                return <span className="text-secondary">{props.row.predictedInspectionResult}</span>
            case "Cancelled":
                return <span className="text-primary">{props.row.predictedInspectionResult}</span>
            case "Failed":
                return <span className="text-danger">{props.row.predictedInspectionResult}</span>
            case "Undetermined":
                return <span className="text-info">{props.row.predictedInspectionResult}</span>
            default:
                return <span></span>
        }
    };

    const keyTermTemplate = (row) => {
        return (
            <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield">{row.keyTerm}</span>
        );
    }

    const commentTemplate = (row) => {
        return (
            <p dangerouslySetInnerHTML={{ __html: row.comment }} ></p>
        );
    }

    return (
        <div id='dialog-detail'>
            <DialogComponent
                width="60%"
                minHeight="40%"
                visible={props.visibility}
                showCloseIcon={true}
                close={() => props.setVisibility(false)}
                header='View Key Terms'
                // buttons={buttons}
                // footerTemplate={footerTemplate}
                isModal={true}
                position={{ X: 'center', Y: 'center' }}
                // ref={dialog => dialogInstance = dialog} 
                overlayClick={() => props.setVisibility(false)}>
                <div>
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Job ID</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Uploaded By</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Link to iSuper</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Project</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Contract</p></td>
                                {/*<td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>CFD</p></td>*/}
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>RISC No</p></td>
                                <td><p className="font-weight-bold text-dark" style={inlineStyle}>ITP Reference</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>ITP Item No</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Discipline</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Location</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.jobId}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.createdBy}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">
                                    <img style={{ cursor: "pointer" }} src={LinkIcon} alt=""
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            window.open(props.row.formLink, "_blank", "noopener,noreferrer");
                                        }} />
                                </p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.project}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.contract}</p></td>
                                {/*<td><p className="mb-4 ml-1 mr-2">{props.row.cfd}</p></td>*/}
                                <td><p className="mb-4 ml-1 mr-2">{props.row.riscNo}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{getItpReference(props.row.itpReference)}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{getItpItemNo(props.row.itpReference, props.row.itpItemNo)}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.discipline}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.location}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Endorser</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Role</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Inspection Date</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Inspection Result</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Predicted Inspection Result</p></td>
                                <td><p className="font-weight-bold text-dark" style={inlineStyle}>Reasons for Prediction</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.endorser}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.role}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.inspectionDate}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.inspectionResult}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{resultTemplate()}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.reasonsForPrediction}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table width="30%">
                        <tbody>
                            <tr>
                                <td width="10%"><p className="font-weight-bold text-dark" style={inlineStyle}>Manual Adjusted</p></td>
                                <td width="10%"><p className="font-weight-bold text-dark" style={inlineStyle}>Reason</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.adjustedInspectionResult}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.reasonsForAdjustment}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <GridComponent
                        height="170px"
                        allowPaging={false}
                        allowTextWrap={true}
                        allowSorting={false}
                        enableStickyHeader={true}
                        dataSource={dataSource}>
                        <ColumnsDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="2%" field='' headerText=''></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="15%" field='keyTerm' headerText='Key Term' template={keyTermTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="20%" field='commentField' headerText='Comment Field'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="" template={commentTemplate} field='comment' headerText='Comment'></ColumnDirective>
                        </ColumnsDirective>
                    </GridComponent>
                </div>
            </DialogComponent>
        </div>

    );

}