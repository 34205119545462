import React, { useRef, useState, useEffect } from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import api from "../../lib/API";
import { useHistory } from "react-router-dom"

import LocalStorageService from "../../lib/LocalStorageService";

export default function Rejected (props) {
    const permission = LocalStorageService.getPermissions();
    const history = useHistory()
    const [params, setParams] = useState(1);
    const [dataSource, setDataSource] = useState(null);
    const [totalRecord, setTotalRecord] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [pageCount, setPageCount] = useState(5);

    const dt = useRef(null);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            api.get(`keyTermDiscovery/rejected`)
                .then(async (res) => {
                    const phraseList = res.data.phraseList;
                    setDataSource(phraseList);
                }, err => {
                    console.log(err.data)
                    setDataSource(null);
                })
        }

        return () => mounted = false;
    }, [params])

    const pagerTemplate = () => {
        return (
            <PagerComponent
                totalRecordsCount={dataSource?.length ?? 0}
                pageSize={pageSize}
                pageCount={pageCount}
                enableQueryString={true}
                enablePersistence={true}
                click={() =>
                    setParams(window.location.href.slice(window.location.href.search('page')))}></PagerComponent>
        )
    }

    const keyTermTemplate = (row) => {
        return (
            <span className="keytermcolumn">
                <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield">{row.keyTerm}</span>
            </span>  
        );
    }

    return (
        <>
            <div className='control-pane'>
                <div className='control-section' >
                    <GridComponent
                        height='100%'
                        allowPaging={true}
                        allowTextWrap={true}
                        allowSorting={true}
                        ref={dt}
                        dataSource={dataSource}
                        pageSettings={{ enableQueryString: true, pageSize: 50, pageCount: 5 }} >
                        <ColumnsDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='phrase' headerText='Phrase'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='discipline' headerText='Discipline'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='keyTerm' headerText='Key Term' template={keyTermTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='reason' headerText='Reason'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='rejectedBy' headerText='Rejected By'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='rejectedDate' headerText='Rejected Time'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Page]} />

                    </GridComponent>
                </div>
            </div>
        </>
    );
}