import React, { useState, useRef, useEffect } from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import api from "../../../lib/API";
import LinkIcon from "../../../images/Link-icon.svg";
import CreateIcon from "../../../images/Create.svg";
import DeleteIcon from "../../../images/icon-delete-row.svg"
import Modal from 'react-bootstrap/Modal';

export default function Reject (props) {

    const inlineStyle = { margin: "0px 5px 0px 5px" };
    const [errorMessage, setErrorMessage] = useState('');
    const confirmRef = useRef(null);
    const [keyTermList, setKeyTermList] = useState([]);
    const [reason, setReason] = useState("");

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            
        }
        return () => mounted = false;
    }, []);

    const handleExit = () => {
        props.setVisibility(false);
        setErrorMessage('');
    }

    const rejectKeyTermDiscovery = async () => {
        await api.post(`keyTermDiscovery/${props.row.keyTermDiscoveryId}/rejected`, {reason: reason})
                .then(res => {
                    props.fetchUpdate()
                }).catch(error => {
                    console.log(JSON.stringify(error.response.data));
                });
    }

    return (
        <div id='dialog-detail'>
            <Modal 
            show={true}
            centered={true}
            onEnter={async ()=>{
            }}
            minHeight="40%"
            visible={props.visibility} 
            showCloseIcon={true}
            close={()=>props.setVisibility(false)}
            header='Key Terms'
            // buttons={buttons}
            // footerTemplate={footerTemplate}
            isModal={true}
            position={{X:'center', Y:'center'}}
            // ref={dialog => dialogInstance = dialog} 
            overlayClick = {() => props.setVisibility(false)}>
                <div class="dialog-header">
                    Reject
                </div>
                <div class="dialog-body">
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="25%" style={inlineStyle}>Phrase</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.phrase}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="dialog-body">
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="12%" style={inlineStyle}>Last Modified By</p></td>
                                <td><p className="font-weight-bold text-dark" style={inlineStyle}>Last Modified Time</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.modifiedBy ?? "-"}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.modifiedDate ?? "-"}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>&nbsp;</div>
                <table width="90%">
                    <tbody>
                        <tr>
                            <td><p className="font-weight-bold text-dark" width="1%" style={inlineStyle}>Discipline</p></td>
                            <td><p className="font-weight-bold text-dark" width="1%" style={inlineStyle}>Key Term</p></td>
                            <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Phrase</p></td>
                            <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Reason</p></td>
                        </tr>
                        {
                            props.row.keyTermList.map(item =>
                                <tr>
                                    <td style={{ padding: "5px" }}><span width="200px" height="50px">{item.discipline}</span></td>
                                    <td style={{ padding: "5px" }}><span width="200px" height="50px">
                                    {
                                        item.newKeyTerm
                                        ?
                                        <span>Other <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield-long">{item.keyTerm}</span></span>
                                        :
                                        <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield-long">{item.keyTerm}</span>
                                    }
                                    </span></td>
                                    <td style={{ padding: "5px" }}><span width="300px" height="50px">{item.phrase}</span></td>
                                    <td style={{ padding: "5px" }}><span width="200px" height="50px">{item.reason}</span></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                <div>&nbsp;</div>
                <table width="90%">
                    <tbody>
                        <tr>
                            <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Reason</p></td>
                        </tr>
                        <tr>
                            <td style={{ padding: "5px" }}><TextBoxComponent
                                value={reason}
                                input={(e)=>setReason(e.value)}
                                change={(e)=>setReason(e.value)}
                                placeholder="Reason" width="500px" height="50px"
                                floatLabelType="Never"
                            /></td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    {
                        <div style={{ paddingTop: "20px", float: "right" }}>
                            <button className="btn cancel mr-2 mb-2 pl-4 pr-4"
                                onClick={() => handleExit()}>Cancel</button>
                            <button
                                ref={confirmRef}
                                className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                                disabled={!reason}
                                onClick={() => {
                                    rejectKeyTermDiscovery();
                                    handleExit();
                                }}
                            >Confirm</button>
                        </div>
                    }
                </div> 
            </Modal>
        </div>
        
    );

}