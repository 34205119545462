import React, {useState, useRef, useEffect} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import api from "../../../lib/API";
import LinkIcon from "../../../images/Link-icon.svg";

export default function DetailDialog (props) {

    const [alertMessage, setAlertMessage] = useState([]);

    const inlineStyle = { margin: "0px 5px 0px 5px" };
    
    useEffect(()=>{
        let mounted = true;
        if(props.visibility && mounted){
            console.log(props.row);
            api.get(`jobs/${props.row.jobId}/alertMessage/${props.row.formId}`)
            .then(async (res) => {
                var alertMessageList = []
                if (res.data.alertMessage) alertMessageList = res.data.alertMessage.split("\n");
                setAlertMessage(alertMessageList)
            },err=>{
                console.log(err.data)
                setAlertMessage('');
            })
        }
        return () => mounted = false;
    },[]);

    const getItpReference = (itpReference) => {
        const parts = itpReference.split('#####');
        return parts[0];
    };

    const getItpItemNo = (itpReference, itpItemNo) => {
        const parts = itpReference.split('#####');
        return (parts.length > 1 ? parts[1] : itpItemNo);
    };

    const resultTemplate = () => {
        switch (props.row.inspectionResult) {
            case "Endorsed":
                return <span className="text-secondary">{props.row.inspectionResult}</span>
            case "Cancelled":
                return <span className="text-primary">{props.row.inspectionResult}</span>
            case "Failed":
                return <span className="text-danger">{props.row.inspectionResult}</span>
            case "Undetermined":
                return <span className="text-info">{props.row.inspectionResult}</span>
            default:
                return <span></span>
        }
    };

    const keyTermTemplate = (row) => {
        return (
            <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield">{row.keyTerm}</span>
        );
    }
    
    return (
        <div id='dialog-detail'>
            <DialogComponent 
            width="60%"
            minHeight="40%"
            visible={props.visibility} 
            showCloseIcon={true}
            close={()=>props.setVisibility(false)}
            header='View Invalid Fields'
            // buttons={buttons}
            // footerTemplate={footerTemplate}
            isModal={true}
            position={{X:'center', Y:'center'}}
            // ref={dialog => dialogInstance = dialog} 
            overlayClick = {() => props.setVisibility(false)}>
                <div>
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Link to iSuper</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Project</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Contract</p></td>
                                {/*<td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>CFD</p></td>*/}
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>RISC No</p></td>
                                <td><p className="font-weight-bold text-dark" style={inlineStyle}>ITP Reference</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>ITP Item No</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Discipline</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Location</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">
                                    <img style={{cursor:"pointer"}} src={LinkIcon} alt="" 
                                    onClick={(e)=>{
                                        e.stopPropagation();
                                        window.open(props.row.formLink, "_blank", "noopener,noreferrer");
                                    }} />
                                    </p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.project}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.contract}</p></td>
                                {/*<td><p className="mb-4 ml-1 mr-2">{props.row.cfd}</p></td>*/}
                                <td><p className="mb-4 ml-1 mr-2">{props.row.riscNo}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{getItpReference(props.row.itpReference)}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{getItpItemNo(props.row.itpReference, props.row.itpItemNo)}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.discipline}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.location}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table width="30%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Inspection Date</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Inspection Result</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.inspectionDate}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{resultTemplate()}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <div style={{overflowY: "scroll", height:"300px"}} className="border p-3">
                        {alertMessage.map((msg,index)=>{
                            return msg!=='' && <p key={index} className="p-1 m-0 text-secondary">{msg}</p>
                        })}
                    </div>
                </div> 
            </DialogComponent>
        </div>
        
    );

}