import React, {useState} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';


export default function ErrorDialog (props) {

    const {fetchUsers} = props;
    const footerTemplate = () => {
        return <button 
        className="btn btn-dark pl-3 pr-3 mb-2 mr-2"
        onClick={handleExit}>Confirm</button>
    }

    const handleExit = () => {
        props.setVisibility(false);
        props.setDeleteError('');
        fetchUsers();
    }


    return (
        <div id='_dialog-content'>
            <DialogComponent 
            width="30%"
            close={handleExit} 
            header='Error' 
            visible={props.visibility} 
            showCloseIcon={false}
            footerTemplate={footerTemplate}
            isModal={true}
            // ref={dialog => dialogInstance = dialog} 
            overlayClick = {handleExit}
            content={`<p class="ml-2">${props.deleteError}</p>`}>

            </DialogComponent>
        </div>
    );

}