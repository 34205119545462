import React, {memo, forwardRef, useImperativeHandle, useEffect, useRef} from "react";

import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Sort } from '@syncfusion/ej2-react-grids';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useHistory } from "react-router-dom";

import Warning from "../../images/Warning.svg";
import Download from "../../images/Download.svg"
import Delete from "../../images/Delete.svg"

import api from "../../lib/API";
import localStorageService from "../../lib/LocalStorageService";

const JobQueueTable = forwardRef((props, ref) => {

    // const [page, setPage] = useState({pageSize: 15});
    const gridRef = useRef(null);
    const permission = localStorageService.getPermissions();

    var page = {pageSize: 50};
    var history = useHistory();
    var isDownloading = false;
    var isRefreshing = false;
    var dataSourceString = null;
    var uploadedTimeFrom = '';
    var uploadedTimeTo = '';
    var uploadedBy = 0;

    function fetchAllJobs(auto=false){
        if (!auto || !isRefreshing) {
            // console.log("refreshing");
            isRefreshing = true;
            api.get(`/jobs?auto=${auto}&uploadedTimeFrom=${uploadedTimeFrom}&uploadedTimeTo=${uploadedTimeTo}&uploadedBy=${uploadedBy}`).then(async (res) => {
                // console.log(res.data.jobList);
                const jobList = res.data.jobList;
                const uploadedBy = res.data.uploadedBy;
                uploadedBy.unshift({
                    "userId": 0,
                    "displayName": "",
                });
                let stringifiedJobList = JSON.stringify(jobList);
                if (gridRef.current && dataSourceString !== stringifiedJobList) {
                    dataSourceString = stringifiedJobList;
                    gridRef.current.dataSource = JSON.parse(stringifiedJobList); // create a copy for the grid to process
                    gridRef.current.refresh();
                    const count = {};
                    jobList.forEach(element => {
                        count[element.status] = (count[element.status] || 0) + 1;
                    });
                    props.setData({
                        "source": jobList,
                        "uploadedBy": res.data.uploadedBy,
                        "totalCount": jobList.length,
                        "pendingCount": (count['Pending']?count['Pending']:0),
                        "inQueueCount": (count["In Queue"]?count["In Queue"]:0),
                        "inProgressCount": (count['In Progress']?count['In Progress']:0),
                        "completedCount": (count['Completed']?count['Completed']:0)
                    });
                }
                isRefreshing = false;
            },err=>{
                isRefreshing = false;
            })
        } else {
            console.log("Skipped auto fetchJobs");
        }
    }

    // auto-refresh after 10 second
    useEffect(()=>{
        const id = setInterval(() => {fetchAllJobs(true);}, (process.env.REACT_APP_AUTO_REFRESH || 10) * 1000);
        return () => clearInterval(id);
    },[])

    const buttonTemplate = (row) =>{
        return (
            <div>
                {row.hasAlert ? (
                    <img style={{cursor:"pointer"}} src={Warning} alt="" 
                    onClick={()=>{
                        props.setWarning(true);
                        props.setRowInfo(row);
                        }} />
                ) : (
                    <img style={{opacity:'0'}} src={Warning} alt="" />
                )}
                {row.status === "Completed" ? (
                    <img style={{cursor:"pointer"}} src={Download} alt=""
                    onClick={(e)=>{
                        e.preventDefault();
                        onDownload(row.jobId, row.filename);
                    }} />
                ) : (
                    <img style={{opacity:'0'}} src={Download} alt="" />
                )}
                {row.status !== "In Progress" ? (
                    permission.search("DELETE_JOB") !== -1 ? (
                        <img style={{cursor:"pointer"}} src={Delete} alt="" 
                        onClick={()=>{
                            props.setDelete(true);
                            props.setRowInfo(row);
                        }} />
                    ) : (
                        <img style={{opacity:'0.5', filter:'grayscale(1)'}} src={Delete} alt="" />
                    )
                ) : (
                    <img style={{opacity:'0'}} src={Delete} alt="" />
                )}
            </div>
        );
    };

    const statusTemplate = (props) =>{
        //props = the whole object of each row
        //console.log(props);
        let status = props.status;
        switch (status) {
            case "Pending":
                return (
                    <span style={{backgroundColor:"#ededf2"}} className="status-badge p-2 pl-3 pr-3">
                        <span className="text-secondary">Pending</span>
                    </span>);
            case "In Queue":
                return (
                    <span style={{backgroundColor:"#e5eeff"}} className="status-badge p-2 pl-3 pr-3">
                        <span className="text-primary">In Queue</span>
                    </span>);
            case "In Progress":
                return (
                    <span style={{backgroundColor:"#f6f3e0"}} className="status-badge p-2 pl-3 pr-3">
                        <span className="text-warning">In Progress</span>
                    </span>);
            case "Completed":
                return (
                <span style={{backgroundColor:"#daf5db"}} className="status-badge p-2 pl-3 pr-3">
                    <span className="text-success">Completed</span>
                </span>);
            default:
                return null

        }
    };

    const jobIdTemplate = (props) => {
        if(props.status !== "Completed")
            return <span className="content-text-inactive">{props.jobId}</span>
        else
            return <span className="content-text">{props.jobId}</span>
    }

    const fileNameTemplate = (props) => {
        
        //console.log(props);
        if(props.status !== "Completed")
            return <TooltipComponent 
            position="BottomCenter"
            content={props.filename}
            ><span className="content-text-inactive">{props.filename}</span></TooltipComponent>
        else
            return (
            <div>
                <TooltipComponent style={{cursor:"pointer"}} position="BottomCenter" content={props.filename}
                onClick={()=>{
                    
                    history.push({
                        pathname:"/job-details",
                        state: {
                            filename: props.filename,
                            jobId: props.jobId,
                            uploadedBy: props.createdBy,
                            uploadedTime: props.uploadedTime,
                            completionTime: props.completionTime
                        }
                    });
                }}>
                    <span className="fileNameWithTip content-text" >{props.filename}</span>
                </TooltipComponent>
            </div>
        );
    };

    const formCountTemplate= (props) => {
        if(props.status !== "Completed")
            return <span className="content-text-inactive">{props.formCount}</span>
        else
            return <span className="content-text">{props.formCount}</span>
    }

    const incorrectCountTemplate= (props) => {
        if(props.status !== "Completed")
            return <span className="content-text-inactive">-</span>
        else
            return <span className="content-text">{props.incorrectCount}</span>
    }

    const createdByTemplate = (props) => {
        if(props.status !== "Completed")
            return <span className="content-text-inactive">{props.createdBy}</span>
        else
            return <span className="content-text">{props.createdBy}</span>
    }

    const uploadedTimeTemplate = (props) => {
        if(props.status !== "Completed")
            return <span className="content-text-inactive">{props.uploadedTime}</span>
        else
            return <span className="content-text">{props.uploadedTime}</span>
    }
    
    const completedTimeTemplate = (props) => {

        if(props.status === "Pending" || props.status === "In Queue" || props.status === "In Progress")
            return <span className="content-text-inactive">-</span>
        else if(props.status === "Completed")
            return <span className="content-text">{props.completionTime}</span>
        else return null;
    }

    const downloadCSV = (blob, fileName="file.zip" ) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        link.remove();
        isDownloading = false;
    };

    const onDownload = async (jobID, fileName) => {
        if(jobID){
            isDownloading = true;
            await api.get(`/jobs/${jobID}/report`,{responseType: 'blob'})
            .then(res =>{
              if (res.status === 200 && res.data) {
                //console.log(res.data);
                fileName = fileName.replace(".csv",'');
                let disposition = res.headers["content-disposition"];
                let idx = disposition.indexOf("filename=");
                if (idx > 0) {
                    fileName = disposition.substring(idx+"filename=".length);
                    fileName = fileName.replaceAll('"', '')
                }
                downloadCSV(res.data, fileName); 
              }else{
                console.log("download failed");
                isDownloading = false;
              }
            }).catch(error => {
              console.log(JSON.stringify(error.response.data));
              isDownloading = false;
            });
        }
      }

    // create ref object
    const publicRef = {
        refresh: (_uploadedTimeFrom, _uploadedTimeTo, _uploadedBy) => {
            // console.log("trigger refresh");
            uploadedTimeFrom = _uploadedTimeFrom;
            uploadedTimeTo = _uploadedTimeTo;
            uploadedBy = _uploadedBy;
            fetchAllJobs();
        },
        batchDownload: () => {
            let downloadList = [];
            if (gridRef.current) {
                let selectedRecords = gridRef.current.getSelectedRecords();
                selectedRecords.forEach(record => {
                    if (record.status === "Completed") {
                        downloadList.push({
                            "jobId": record.jobId,
                            "filename": record.filename,
                        });
                    }
                });
            }
            downloadList.forEach(row => {
                onDownload(row.jobId, row.filename);
            });
        },
    };
    
    // pass the ref and a function that returns our object
    useImperativeHandle(ref, () => publicRef);

    return (
        <div className="table-content p-3">
            <GridComponent
            ref={gridRef}
            height= '60vh'
            dataSource={[]} 
            allowSorting={true} 
            allowPaging={true} 
            pageSettings={page} 
            sortSettings={{columns:[{field:'jobId',direction:'Descending'}]}}
            selectionSettings={{checkboxOnly:true}}
            created={fetchAllJobs} >
                <ColumnsDirective>
                    <ColumnDirective headerTextAlign="center" textAlign="center" type="checkbox" width="50px" />
                    <ColumnDirective headerTextAlign="center" textAlign="center" field="jobId" width="100px" headerText='Job ID' template={jobIdTemplate} />
                    <ColumnDirective headerTextAlign="left" textAlign="left" field="filename" width="450px"headerText='File Name' template={fileNameTemplate} />
                    <ColumnDirective headerTextAlign="left" textAlign="left" field="formCount" width="175px" headerText='Form Count' template={formCountTemplate} />
                    <ColumnDirective headerTextAlign="left" textAlign="left" field="incorrectCount" width="175px" headerText='Incorrect Count' template={incorrectCountTemplate} />
                    <ColumnDirective headerTextAlign="left" textAlign="left" field="createdBy" width="175px" headerText='Uploaded By' template={createdByTemplate}  />
                    <ColumnDirective headerTextAlign="left" textAlign="left" field="uploadedTime" width="175px" headerText='Uploaded Time' template={uploadedTimeTemplate} />
                    <ColumnDirective headerTextAlign="left" textAlign="left" field="completionTime" width="175px" headerText='Completed Time' template={completedTimeTemplate} />
                    <ColumnDirective headerTextAlign="left" textAlign="left" field="status" headerText='Status' width="135px"  template={statusTemplate} />
                    <ColumnDirective headerTextAlign="center" textAlign="right" field="button" width="140px" headerText='' template={buttonTemplate} allowSorting={false} />
                </ColumnsDirective>
                <Inject services={[Page,Sort]}/>
            </GridComponent>
        </div>
    )
});

export default memo(JobQueueTable);