import React, { useState, useRef, useEffect } from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent, initForeignKeyColumn } from '@syncfusion/ej2-react-grids';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import api from "../../../lib/API";
import LinkIcon from "../../../images/Link-icon.svg";
import CreateIcon from "../../../images/Create.svg";
import DeleteIcon from "../../../images/icon-delete-row.svg"
import Modal from 'react-bootstrap/Modal';

export default function PendingEdit (props) {

    const [dataSource, setDataSource] = useState(null);

    const inlineStyle = { margin: "0px 5px 0px 5px" };

    const [errorMessage, setErrorMessage] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const confirmRef = useRef(null);
    const [keyTermList, setKeyTermList] = useState([]);
    const [keyTermCount, setKeyTermCount] = useState(0);

    const fullKeyTermList = {
        "General": [
            "Non compliance with specifications / drawings / design / standard",
            "Incorrect material type / size / pattern / colour",
            "Inspection item not found",
            "Instrument damaged",
            "No permit to work",
            "Deformation / deflection",
            "Poor workmanship",
            "wrong / missing information",
            "duplicate risc form",
            "inappropriate information",
            "re-sequence work",
            "work not ready",
            "contractor cancel inspection",
            "Unpredictable events",
            "Late Submission",
            "outstanding works",
            "outstanding submission",
        ],
        "ABWF": [
            "Blockwork & Partitions Defects",
            "Doors & Shutters Defects",
            "Facade & Canopies defects",
            "False ceiling & lightings defects",
            "Fire protectoin defects",
            "Handrails & Balustrades Defects",
            "Signage Defects",
            "Tiling / VE / Aluminum Panels defects",
            "Other ABWF Issues",
        ],
        "CS": [
            "Concrete defects",
            "Incorrect concrete grade",
            "Drainage works defects",
            "Roadwork defects",
            "Steel related defects",
            "defective coupler",
            "Civil & Structure - Water Proofing Issue",
            "Bridge & Viaduct related defects",
            "Pre-cast elemet defects",
        ],
        "FDN": [
            "Foundation defects",
        ],
        "GEO": [
            "Geotechnical Defects",
            "Observable Geotechnical Risks",
        ],
        "OHL": [
            "Overhead Line - Support Issue",
            "Overhead Line - Wire Issues",
            "Overhead Line - Switch Gear / Equipment Issue",
        ],
        "PWY": [
            "Permanent Way / Trackworks Defects",
        ],
        "EM": [
            "Inaccessibility",
            "lack of protection",
            "Support defects",
            "improper labelling",
            "E&M - Energy Related Defects",
            "Electrical Installation Related Defects",
            "Lightning System related defects",
            "Pipework & Equipment defects",
            "Water quality issue",
            "Control System defects",
            "ECS & TECS related defects",
            "Mechanical Installation defects",
            "SIG System related Defects",
            "E&M - RS related defects",
            "Communication System Defects",
            "Lifts & Escalator Defects",
            "Doors related Defects",
        ],
        "BS": [
            "Inaccessibility",
            "lack of protection",
            "Support defects",
            "improper labelling",
            "E&M - Energy Related Defects",
            "Electrical Installation Related Defects",
            "Lightning System related defects",
            "Pipework & Equipment defects",
            "Water quality issue",
            "Control System defects",
            "ECS & TECS related defects",
            "Mechanical Installation defects",
            "SIG System related Defects",
            "E&M - RS related defects",
            "Communication System Defects",
            "Lifts & Escalator Defects",
            "Doors related Defects",
        ]
    }

    const disciplineList = Object.keys(fullKeyTermList).sort((a, b) => {
        if (a == "General") {
            return -1;
        }
        if (b == "General") {
            return 1;
        }
        return a > b ? 1 : -1;
    });

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            console.log(props.row.keyTermList);
            setKeyTermList(props.row.keyTermList.map((item, index) => {
                return {
                    "index": index,
                    "discipline": item.discipline,
                    "keyTerm": item.newKeyTerm ? "Other" : item.keyTerm,
                    "newKeyTerm": item.newKeyTerm ? item.keyTerm : "",
                    "disciplineKeyTermList": (fullKeyTermList[item.discipline] || []).concat("Other"),
                    "phrase": item.phrase,
                    "reason": item.reason,
                    "delete": false,
                };
            }));
            setKeyTermCount(props.row.keyTermList.length);
        }
        return () => mounted = false;
    }, []);

    const handleExit = () => {
        setIsConfirmed(false);
        props.setVisibility(false);
        setErrorMessage('');
    }

    const addRow = () => {
        console.log(keyTermCount);
        // Determine the discipline to use
        const discipline = disciplineList[0];
    
        // Initialize disciplineKeyTermList based on the discipline
        const disciplineKeyTermList = (fullKeyTermList[discipline] || []).concat("Other");
    
        // Determine the keyTerm to use
        const keyTerm = disciplineKeyTermList[0]; // Use "Other" as default if no key terms are available
    
        // Add the new row to keyTermList
        setKeyTermList([...keyTermList, {
            "index": keyTermList.length,
            "discipline": discipline,
            "keyTerm": keyTerm,
            "disciplineKeyTermList": disciplineKeyTermList,
            "phrase": "",
            "reason": "",
            "delete": false,
        }]);

        setKeyTermCount(keyTermCount + 1);
    }

    const updateDiscipline = (index, discipline) => {
        let disciplineKeyTermList = fullKeyTermList[discipline] || [];
        disciplineKeyTermList = [...disciplineKeyTermList, "Other"];
        const keyTerm = disciplineKeyTermList[0] 
        setKeyTermList([
            ...keyTermList.slice(0, index),
            {
                ...keyTermList[index],
                "discipline": discipline,
                "keyTerm": keyTerm,
                "disciplineKeyTermList": disciplineKeyTermList,
            },
            ...keyTermList.slice(index + 1),
        ]);
    }

    const updateDisciplineKeyTerm = (index, disciplineKeyTerm) => {
        setKeyTermList([
            ...keyTermList.slice(0, index),
            {
                ...keyTermList[index],
                "keyTerm": disciplineKeyTerm,
                "newKeyTerm": disciplineKeyTerm === "Other" ? keyTermList[index]["newKeyTerm"] : "",
            },
            ...keyTermList.slice(index + 1),
        ]);
    }

    const updateNewKeyTerm = (index, newKeyTerm) => {
        setKeyTermList([
            ...keyTermList.slice(0, index),
            {
                ...keyTermList[index],
                "newKeyTerm": newKeyTerm,
            },
            ...keyTermList.slice(index + 1),
        ]);
    }

    const updatePhrase = (index, phrase) => {
        setKeyTermList([
            ...keyTermList.slice(0, index),
            {
                ...keyTermList[index],
                "phrase": phrase,
            },
            ...keyTermList.slice(index + 1),
        ]);
    }

    const updateReason = (index, reason) => {
        setKeyTermList([
            ...keyTermList.slice(0, index),
            {
                ...keyTermList[index],
                "reason": reason,
            },
            ...keyTermList.slice(index + 1),
        ]);
    }

    const updateDelete = (index, _delete) => {
        console.log(keyTermCount);
        setKeyTermList([
            ...keyTermList.slice(0, index),
            {
                ...keyTermList[index],
                "delete": _delete,
            },
            ...keyTermList.slice(index + 1),
        ]);
        setKeyTermCount(keyTermCount - 1);
    }

    const updateKeyTermDiscovery = () => {
        const params = {
            "keyTermList": keyTermList.filter(item => !item.delete).map(item => {
                return {
                    "discipline": item.discipline,
                    "keyTerm": item.keyTerm === "Other" ? item.newKeyTerm : item.keyTerm,
                    "newKeyTerm": item.keyTerm === "Other",
                    "phrase": item.phrase,
                    "reason": item.reason,
                };
            }),
        };
        console.log(params);
        api.post(`keyTermDiscovery/${props.row.keyTermDiscoveryId}`, params).then(async res => {
            console.log(res.data);
            props.fetchUpdate();
            handleExit();
        }, err => {
            setErrorMessage(err.response.data.message);
            console.log(err);
            setIsConfirmed(false);
        });
    }

    return (
        <div id='dialog-detail'>
            <Modal 
            show={true}
            centered={true}
            minHeight="40%"
            visible={props.visibility} 
            showCloseIcon={true}
            close={()=>props.setVisibility(false)}
            header='Edit'
            isModal={true}
            position={{X:'center', Y:'center'}}
            overlayClick = {() => props.setVisibility(false)}>
                <div class="dialog-header">
                    Edit
                </div>
                <div>
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="100%" style={inlineStyle}>Phrase</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.phrase}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="dialog-body">
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="12%" style={inlineStyle}>Last Modified By</p></td>
                                <td><p className="font-weight-bold text-dark" style={inlineStyle}>Last Modified Time</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.modifiedBy ?? "-"}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.modifiedDate ?? "-"}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <table width="90%">
                    <tbody>
                        <tr>
                            <td><p className="font-weight-bold text-dark" width="1%" style={inlineStyle}>Discipline</p></td>
                            <td><p className="font-weight-bold text-dark" width="1%" style={inlineStyle}>Key Term</p></td>
                            <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Phrase</p></td>
                            <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Reason</p></td>
                        </tr>
                        {
                            keyTermList && keyTermList.filter(item => !item.delete).map(item =>
                                <>
                                    <tr>
                                        <td style={{ padding: "5px" }}><DropDownListComponent
                                            placeholder="Discipline" width="200px" height="50px"
                                            dataSource={disciplineList}
                                            value={item.discipline}
                                            change={(e) => {
                                                updateDiscipline(item.index, e.value);
                                            }}
                                        /></td>
                                        <td style={{ padding: "5px" }}><DropDownListComponent
                                            placeholder="Key Term" width="200px" height="50px"
                                            dataSource={item.disciplineKeyTermList}
                                            value={item.keyTerm}
                                            change={(e) => {
                                                updateDisciplineKeyTerm(item.index, e.value);
                                            }}
                                        /></td>
                                        <td style={{ padding: "5px" }}><TextBoxComponent
                                            value={item.phrase}
                                            placeholder="Phrase" width="300px" height="50px"
                                            floatLabelType="Never"
                                            input={(e) => updatePhrase(item.index, e.value)}
                                            change={(e) => updatePhrase(item.index, e.value)}
                                        /></td>
                                        <td style={{ padding: "5px" }}><TextBoxComponent
                                            value={item.reason}
                                            placeholder="Reason" width="200px" height="50px"
                                            floatLabelType="Never"
                                            input={(e) => updateReason(item.index, e.value)}
                                            change={(e) => updateReason(item.index, e.value)}
                                        /></td>
                                        <td style={{ textAlign: "center", verticalAlign: "top" }}><img
                                            style={{ cursor: keyTermCount === 1 ? "default" : "pointer", filter: keyTermCount === 1 ? "opacity(0.5)" : "none", width: "30px", paddingLeft: "6px", paddingTop: "12px" }}
                                            src={DeleteIcon}
                                            alt=""
                                            onClick={keyTermCount === 1 ? null : (e) => {
                                                updateDelete(item.index, true)
                                            }}
                                        /></td>
                                    </tr>
                                    {
                                        item.keyTerm === "Other"
                                        ?
                                        <tr>
                                            <td style={{ padding: "5px" }}>&nbsp;</td>
                                            <td style={{ padding: "5px" }}><TextBoxComponent
                                                value={item.newKeyTerm}
                                                placeholder="Key Term" width="200px" height="50px"
                                                floatLabelType="Never"
                                                input={(e) => updateNewKeyTerm(item.index, e.value)}
                                                change={(e) => updateNewKeyTerm(item.index, e.value)}
                                            /></td>
                                            <td style={{ padding: "5px" }}>&nbsp;</td>
                                            <td style={{ padding: "5px" }}>&nbsp;</td>
                                            <td style={{ textAlign: "center", verticalAlign: "top" }}>&nbsp;</td>
                                        </tr>
                                        :
                                        null
                                    }
                                </>
                            )
                        }
                    </tbody>
                </table>
                <div>
                    <div style={{ paddingTop: "20px" }}>
                        <button
                            className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                            onClick={() => addRow()}><img src={CreateIcon} alt="" /> Add</button>
                    </div>
                    <div className="col-7">
                        {errorMessage && <p style={{ textAlign: "left !important" }} className="float-left mt-2 text-left text-danger m-0">{errorMessage}</p>}
                    </div>
                    <div style={{ paddingTop: "20px", float: "right" }}>
                        <button className="btn cancel mr-2 mb-2 pl-4 pr-4"
                            onClick={() => handleExit()}>Cancel</button>
                        <button
                            ref={confirmRef}
                            className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                            disabled={keyTermList && keyTermList.filter(item => !item.delete).some(item => !item.discipline || !item.keyTerm || (item.keyTerm === "Other" && !item.newKeyTerm) || !item.phrase || !item.reason)}
                            onClick={() => {
                                setIsConfirmed(true);
                                updateKeyTermDiscovery();
                            }}
                        >Confirm</button>
                    </div>
                </div> 
            </Modal>
        </div>
        
    );

}