import React, {useState, useRef, useEffect} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';

import DeleteButtonLogo from "../../../images/Delete-button.svg";
import api from "../../../lib/API";


export default function WarningDialog (props) {

    const [deleteClicked, setDeleteClicked] = useState(false);
    const [ignoreClicked, setIgnoreClicked] = useState(false);
    const [ignoreDisabled, setIgnoreDisabled] = useState(false);
    const [alertMessage, setAlertMessage] = useState([]);
    const [duplicateCfd, setDuplicateCfd] = useState([]);
    const deleteRef = useRef(null);
    const ignoreRef = useRef(null);

    const {fetchJobs} = props;

    useEffect(()=>{        
        let mounted = true;
        if(props.visibility && mounted && props.row.jobId){
            api.get(`jobs/${props.row.jobId}?alert=true`)
            .then(async (res) => {
                var alertMessageList = []
                if (res.data.alertMessage) alertMessageList = res.data.alertMessage.split("\n");
                setAlertMessage(alertMessageList)
                setIgnoreDisabled(alertMessageList.length > 0 && alertMessageList[0] === "Please contact system administrator.")
                var duplicateCfdList = []
                if (res.data.duplicateCfd) duplicateCfdList = res.data.duplicateCfd;
                setDuplicateCfd(duplicateCfdList)
            },err=>{
                console.log(err.data)
                setAlertMessage([])
                setDuplicateCfd([])
            })
        }

        return () => mounted = false;
    },[props]);

    useEffect(()=>{
        // console.log(deleteRef);
        let mounted = true;
        if(props.visibility && mounted && deleteRef.current){
            if(deleteClicked){
                deleteRef.current.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-2">Delete</span>`;
                deleteRef.current.setAttribute("disabled","disabled");
            }
            else{
                deleteRef.current.removeAttribute("disabled");
                deleteRef.current.innerHTML = `<img src=${DeleteButtonLogo} alt="" /><span>Delete</span>`;
            }
        }

        return () => mounted = false;
    },[props, deleteClicked]);

    useEffect(()=>{
        let mounted = true;
        if(props.visibility && mounted && ignoreRef.current){
            if(ignoreClicked){
                ignoreRef.current.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-2">Ignore and Continue</span>`;
                ignoreRef.current.setAttribute("disabled","disabled");
            }
            else{
                ignoreRef.current.removeAttribute("disabled");
                ignoreRef.current.innerHTML = `<span>Ignore and Continue</span>`;
            }
        }

        return () => mounted = false;
    },[props, ignoreClicked])

    const onDeleteClick = () => {
        setDeleteClicked(true);
        api.post(`/jobs/${props.row.jobId}`, {continue: false})
        .then(async res => {
            console.log(res.data);
            setDeleteClicked(false);
            // deleteRef.stop();
            props.setVisibility(false);
            fetchJobs();
            })
        .catch( err => {
            setDeleteClicked(false);
            console.log(err);
        })
    };

    const onIgnoreClick = () => {
        setIgnoreClicked(true);
        api.post(`/jobs/${props.row.jobId}`, {continue: true})
        .then(async res => {
            setIgnoreClicked(false);
            console.log(res.data);
            // ignoreRef.stop();
            props.setVisibility(false);
            fetchJobs();
            })
        .catch( err => {
            setIgnoreClicked(false);
            console.log(err);
        })
    };

    const footerTemplate = () => {
        return (
            <div>
                <button
                ref={deleteRef}
                className="btn btn-danger pl-4 pr-4 mr-2 mb-2"
                onClick={onDeleteClick}><img src={DeleteButtonLogo} alt="" />Delete</button>

                <button
                ref={ignoreRef}
                className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                disabled={ignoreDisabled}
                onClick={onIgnoreClick}>Ignore and Continue</button>
            </div>
        );
    }

    return (
        <div id='dialog-target'>
            <DialogComponent 
            width="80%"
            minHeight={props.row.status === "Pending" ? "72%" : "50%"}
            close={()=>props.setVisibility(false)} 
            header='Warning' 
            visible={props.visibility} 
            showCloseIcon={false} 
            // buttons={buttons}
            footerTemplate={props.row.status === "Pending" ? footerTemplate : null}
            isModal={true}
            position={{X:'center', Y:'center'}}
            // ref={dialog => dialogInstance = dialog} 
            overlayClick = {() => props.setVisibility(false)}>
                <p className="mb-4 ml-1 mr-2">{`Please check below error(s) found in the selected item (Job ID: ${props.row.jobId})`}</p>
                
                <div className="row m-1" >
                    <div className="col-2 font-weight-bold text-dark" style={{maxWidth: "130px"}}>File Name</div>
                    <div className="col-8">{props.row.filename}</div>
                </div>
                <div className="row m-1" >
                    <div className="col-2 font-weight-bold text-dark" style={{maxWidth: "130px"}}>Uploaded By</div>
                    <div className="col-8">{props.row.createdBy}</div>
                </div>
                <div className="row m-1" >
                    <div className="col-2 font-weight-bold text-dark" style={{maxWidth: "130px"}}>Uploaded Time</div>
                    <div className="col-8">{props.row.uploadedTime}</div>
                </div>
                {/* <textarea rows={20} cols={130} style={{resize:"none"}} readOnly>
                    
                </textarea> */}
                <div style={{overflowY: "scroll", height:"300px"}} className="border p-3">
                    {props.row.jobId && alertMessage.map((msg,index)=>{
                        // var message = String(res.alertMessage)
                        // message= message.split("\n");
                        return msg!=='' && <p key={index} className="p-1 m-0 text-secondary">{msg}</p>
                    })}
                </div>

                <div className="mt-4">
                    <div className="font-weight-bold text-dark">Duplicate CFD</div>
                </div>

                <div style={{overflowY: "scroll", height:"100px"}} className="border p-3">
                    {props.row.jobId && duplicateCfd.map((msg,index)=>{
                        return msg!=='' && <p key={index} className="p-1 m-0 text-secondary">{msg}</p>
                    })}
                </div>

                {props.row.status === "Pending" ? (
                    <div className="p-3 mt-3"  style={{backgroundColor:"#EDEDF2"}}>
                        <p className="m-0">Click <span className="font-weight-bold">Delete</span>: the process will be stopped and the file will be deleted from the system.</p>
                        <p className="m-0">Click <span className="font-weight-bold">Ignore and Continue</span>: the process will be continued without considering the form(s) with error(s).</p>
                    </div>
                ) : (
                    null
                )}
            </DialogComponent>
        </div>
        
    );

}