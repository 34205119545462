import React, { useState, useEffect} from "react";
import ChangePasswordDialog from "./Dialog/ChangePasswordDialog";

import api from "../../lib/API";

export default function Profile (){

    const [visibility,setVisibility] = useState(false);
    const [username, setUsername] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [role, setRole] = useState('');
    const [section, setSection] = useState('');

    const handleClick=()=>{
        
        setVisibility(true);
    };

    useEffect(()=>{
        api.get('/profile').then(async (res) => {
          setUsername(res.data.username);
          setDisplayName(res.data.displayName);
          setRole(res.data.roleName);
          setSection(res.data.section)
      }, err => {
          console.log(err.data);
      })
      },[])

    return (
        <div className="content">
            <h4 className="page-title mb-4">User Profile</h4>
            <div className="info">
            <div style={{width:"15%"}}>
                <table className="table table-sm table-borderless">
                    <tbody>
                        <tr>
                            <td className="font-weight-bold">Username</td>
                            <td className="text-secondary">{username}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Name</td>
                            <td className="text-secondary">{displayName}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Role</td>
                            <td className="text-secondary">{role}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Section</td>
                            <td className="text-secondary">{section}</td>
                        </tr>
                    </tbody>
                </table>
                {
                    process.env.REACT_APP_PASSWORD_LOGIN ? <button className="btn btn-dark pl-4 pr-4" onClick={handleClick}>
                        Change Password
                    </button> : null
                }
            </div>
                <ChangePasswordDialog 
                    visibility={visibility}
                    setVisibility={setVisibility}
                />

            </div>

        </div>
    );

};